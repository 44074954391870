<template>
  <div class="footer">
    <div class="top">
      <div class="left">
        <div class="list">
          <a href="javascript:;" @click="jump(0)" style="font-size:20px;font-weight:bolder;">小缘首页</a>
        </div>
        <div class="list">
          <a href="javascript:;" @click="jump(1)">小缘系统</a>
        </div>
        <!-- <div class="list">
          <a href="javascript:;" @click="jump(2)">产品中心</a>
        </div> -->
        <div class="list">
          <a href="javascript:;" @click="jump(3)">服务支持</a>
        </div>
        <div class="list" @click="about()">关于小缘</div>
      </div>
      <div class="center">
        <div class="list" style="font-size:20px;font-weight:bolder;">商务合作</div>
        <div class="list">如果您有商务合作的需要请联系我们</div>
        <div class="list">电话：0571-87766335</div>
        <div class="list">邮箱：postmaster@cxc-group.com</div>
        <div class="list">地址：杭州市西湖区三墩镇留祥路西行桥74号丰瑞大厦B幢401室</div>
      </div>
      <div class="right">
        <div style="height:40px;line-height:40px;font-weight:bolder;font-size:20px;">关注我们</div>
        <img src="https://cdn.cxc-group.com/website/ewm.jpg" class="serve" />
        <div style="height:40px;line-height:40px;margin-top:-20px;">微信扫一扫关注我们</div>
      </div>
    </div>
    <div class="bottom">
      <a class="copyright" href="https://beian.miit.gov.cn/">Copyright 2020 杭州小缘文化科技有限公司 版权所有</a>
      <a
        class="copyright"
        target="_blank"
        href="https://beian.miit.gov.cn"
        style="display:inline-block;text-decoration:none;height:20px;line-height:20px;"
      >
        <img src="https://cdn.cxc-group.com/website/footer.png" style="float:left;" />
        <p
          style="float:left;height:20px;line-height:20px;margin: 0px 0px 0px 5px; color:#939393;"
        >浙ICP备2020036517号</p>
      </a>
    </div>
  </div>
</template>

<script>
// import HelloWorld from '@/components/HelloWorld.vue'

export default {
  name: "header",
  components: {},
  methods: {
    home() {
      this.$router.push({ name: "Home" });
    }
  }
};
</script>
<style lang="scss" scoped="scoped">
.footer {
  font-family: "幼圆";
  width: 100%;
  // height: 400px;
  line-height: 80px;
  color: #ccc;
  background: #12355b;
  .top {
    display: flex;
    justify-content: flex-start;
    padding-top: 50px;
    .left {
      width: 20%;
      text-align: left;
      margin-left: 10%;
      .list {
        cursor: pointer;
        line-height: 50px;
        a {
          color: #ccc;
        }
      }
    }
    .center {
      width: 30%;
      margin-left: 5%;
      text-align: left;
      .list {
        line-height: 50px;
      }
    }
    .right {
      width: 20%;
      margin-left: 10%;
      text-align: left;
      img {
        width: 55%;
        margin-top: 20px;
      }
    }
  }
  .bottom {
    width: 700px;
    display: flex;
    justify-content: flex-start;
    margin: 10px auto 0 auto;
    font-size: 14px;
  }
  .copyright {
    display: block;
    height: 20px;
    line-height: 20px;
    margin: 0px 0px 0px 5px;
    color: #939393;
    padding: 20px 0;
  }
}
</style>
