<template>
  <div class="about-box">
    <van-nav-bar title="小缘文化 CXC-GROUP.COM" left-arrow @click-left="show=true">
      <template #left>
        <van-icon name="wap-nav" size="18" />
      </template>
    </van-nav-bar>
    <div class="top-box">
      <van-image src="https://cdn.cxc-group.com/website/new/52.png" />
      <div class="top-title">
        <van-image src="https://cdn.cxc-group.com/website/new/53.png" />
      </div>
      <div class="top-hr">
        <van-image src="https://cdn.cxc-group.com/website/new/12.png" fit="contain" />
        <van-image src="https://cdn.cxc-group.com/website/new/54.png" fit="contain" />
        <van-image src="https://cdn.cxc-group.com/website/new/13.png" fit="contain" />
      </div>

      <div class="top-text">
        <van-image src="https://cdn.cxc-group.com/website/new/12.png" fit="contain" />
        <p>
          小缘文化深耕互联网领域十余载，
          <br />研发可量产的智能硬件终端，
          <br />打造一体化行业解决方案，
          <br />为“更智慧，更满意”赋能
        </p>
        <van-image src="https://cdn.cxc-group.com/website/new/13.png" fit="contain" />
      </div>
    </div>
    <div class="content-box">
      <div class="content-title">
        <van-image src="https://cdn.cxc-group.com/website/new/55.png" fit="contain"></van-image>
      </div>
      <p>
        杭州小缘文化科技有限公司成立于2020年9月11日。
        <br />公司以软硬产品研发为核心，
        <br />打造基于O2O体系的智慧服务系统，
        <br />让管理便捷、高效、安全、精准！
      </p>
      <div class="content">
        <div class="content-list">
          <div class="content-list-title">
            <van-image src="https://cdn.cxc-group.com/website/new/56.png" fit="cover" />
          </div>
          <div class="content-list-image">
            <van-image src="https://cdn.cxc-group.com/website/new/60.png" fit="cover" />
          </div>
          <div class="content-list-text-box">
            <div>
              <van-image src="https://cdn.cxc-group.com/website/new/10.png" fit="contain"></van-image>
              <p>服务与企业，共结今世善缘</p>
              <van-image src="https://cdn.cxc-group.com/website/new/10.png" fit="contain"></van-image>
            </div>
          </div>
        </div>
        <div class="content-list">
          <div class="content-list-title">
            <van-image src="https://cdn.cxc-group.com/website/new/58.png" fit="cover" />
          </div>
          <div class="content-list-image">
            <van-image src="https://cdn.cxc-group.com/website/new/61.png" fit="cover" />
          </div>
          <div class="content-list-text-box">
            <div>
              <van-image src="https://cdn.cxc-group.com/website/new/10.png" fit="contain"></van-image>
              <p>成为服务平台领先品牌</p>
              <van-image src="https://cdn.cxc-group.com/website/new/10.png" fit="contain"></van-image>
            </div>
          </div>
        </div>
        <div class="content-list">
          <div class="content-list-title">
            <van-image src="https://cdn.cxc-group.com/website/new/57.png" fit="cover" />
          </div>
          <div class="content-list-image">
            <van-image src="https://cdn.cxc-group.com/website/new/62.png" fit="cover" />
          </div>
          <div class="content-list-text-box">
            <div>
              <van-image src="https://cdn.cxc-group.com/website/new/10.png" fit="contain"></van-image>
              <p>诚信、平等、圆融、共赢</p>
              <van-image src="https://cdn.cxc-group.com/website/new/10.png" fit="contain"></van-image>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="footer-box">
      <div class="footer-list-box">
        <div class="footer-title">关注我们</div>
        <div class="footer-list2" style="margin-top: 4vw;">
          <van-image src="https://cdn.cxc-group.com/website/ewm.jpg" fit="cover" />
        </div>
        <div class="footer-list2">微信扫一扫关注我们</div>
      </div>
      <div class="footer-list-box">
        <div class="footer-title">商务合作</div>
        <div class="footer-list2">如果您有商务合作的需要请联系我们</div>
        <div class="footer-list" style="margin-top: 4vw;">
          <a href="tel:0571-87766335">
            <van-icon name="phone" />0571-87766335
          </a>
        </div>
        <div class="footer-list">
          <van-icon name="invition" />postmaster@cxc-group.com
        </div>
        <div class="footer-list">
          <van-icon name="map-marked" />杭州市西湖区三墩镇留祥路西行桥74号丰瑞大厦B幢401室
        </div>
      </div>

      <div data-v-94f0d06a class="bottom">
        <a
          data-v-94f0d06a
          href="https://beian.miit.gov.cn/"
          class="copyright"
        >Copyright 2020 杭州小缘文化科技有限公司 版权所有</a>
        <a
          data-v-94f0d06a
          target="_blank"
          href="https://www.beian.gov.cn/portal/registerSystemInfo?recordcode=33010602011347"
          class="copyright"
          style="display: inline-block; text-decoration: none; height: 20px; line-height: 20px;"
        >
          <img
            data-v-94f0d06a
            src="https://cdn.cxc-group.com/website/footer.png"
            style="float: left;"
          />
          <p
            data-v-94f0d06a
            style="float: left; height: 20px; line-height: 20px; margin: 0px 0px 0px 5px; color: rgb(147, 147, 147);"
          >浙公网安备 33010602011347号</p>
        </a>
      </div>
    </div>

    <van-popup
      v-model="show"
      position="top"
      closeable
      close-icon-position="top-left"
      :style="{ height: '100%' }"
    >
      <div class="menu-box">
        <div class="menu-title">小缘文化 CXC-GROUP.COM</div>
        <div class="menu">
          <div class="menu-list" @click="goHome(0)">首页</div>
          <div class="menu-list" @click="goHome(1)">小缘系统</div>
          <div class="menu-list" @click="goHome(2)">产品中心</div>
          <div class="menu-list" @click="goHome(3)">服务支持</div>
          <div class="menu-list">关于小缘</div>
        </div>
      </div>
    </van-popup>
  </div>
</template>

<script>
export default {
  name: "MobileAbout",
  data() {
    return {
      show: false
    };
  },
  methods: {
    goHome(index) {
      localStorage.setItem("mobilePage", index);
      this.$router.push("/m/");
    }
  },
  mounted() {}
};
</script>

<style scoped lang="scss">
.menu-box {
  width: 100%;
  height: 100%;
  background-color: #12355b;
  .menu-title {
    max-width: 100%;
    margin: 0 auto;
    font-weight: 500;
    font-size: 16px;
    line-height: 46px;
    color: #f4caab;
    border-bottom: 1px solid rgba(74, 112, 153, 0.9);
  }
  .menu {
    width: 90%;
    text-align: left;
    padding: 5%;
    &-list {
      width: 80%;
      margin: 0 auto;
      height: 52px;
      line-height: 52px;
      color: #f4caab;
      border-bottom: 1px solid rgba(74, 112, 153, 0.9);
    }
  }
}
.about-box {
  width: 100%;
  padding-top: 46px;

  .top-box {
    width: 100%;
    height: 55vw;
    position: relative;
    .van-image {
      width: 100%;
      height: 100%;
    }

    .top-title {
      width: 60vw;
      position: absolute;
      top: 6vw;
      left: 50%;
      margin-left: -30vw;
      .van-image {
        width: 100%;
        height: 100%;
      }
    }
    .top-hr {
      width: 30vw;
      position: absolute;
      display: inline-flex;
      height: 10vw;
      left: 50%;
      margin-left: -15vw;
      top: 16vw;
    }
    .top-text {
      width: 98vw;
      position: absolute;
      display: inline-flex;
      left: 50%;
      margin-left: -48vw;
      top: 26vw;
      align-items: center;
      .van-image {
        width: 40%;
        height: 3vw;
        margin-left: -4px;
      }
      p {
        width: 50%;
        flex-shrink: 0;
        font-size: 2vw;
        font-family: FZLanTingHei-L-GBK;
        font-weight: 400;
        color: #ffffff;
        line-height: 5vw;
      }
    }
  }
  .content-box {
    width: 100%;
    padding-top: 10vw;
    padding-bottom: 10vw;
    position: relative;
    .content-title {
      width: 100%;
      .van-image {
        width: 100%;
        height: 7vw;
      }
      .content-title-text {
        height: 12px;
        text-align: center;
        display: inline-flex;
        align-items: center;
        margin: 0 auto;
        margin-top: 4vw;
        .van-image {
          height: 8px;
        }
        span {
          font-size: 10px;
          line-height: 12px;
          font-family: FZLanTingHei-R-GBK;
          font-weight: 400;
          color: #000000;
          white-space: nowrap;
        }
      }
    }
    p {
      width: 100%;
      text-align: center;
      margin: 0 auto;
      margin-top: 5vw;
      font-size: 2vw;
      font-family: FZLanTingHei-L-GBK;
      font-weight: 400;
      color: #666666;
      line-height: 5vw;
    }

    .content {
      width: 90%;
      margin: 0 auto;
      margin-top: 15vw;
      padding-bottom: 4vw;
      &-list {
        width: 100%;
        height: 80vw;
        background-color: #dfdfdf;
        position: relative;
        border-radius: 1vw;
        margin-bottom: 8vw;
        .content-list-title {
          position: absolute;
          width: 35vw;
          height: 12vw;
          left: 50%;
          top: -6vw;
          margin-left: -17.5vw;
          .van-image {
            width: 100%;
            height: 100%;
            z-index: 2;
          }
        }

        .content-list-image {
          width: 100%;
          height: 52vw;
          .van-image {
            width: 100%;
            height: 100%;
          }
        }

        .content-list-text-box {
          position: absolute;
          bottom: 0;
          left: 0;
          width: 100%;
          height: 28vw;
          background-color: #12355b;
          border-bottom-left-radius: 1vw;
          border-bottom-right-radius: 1vw;
          display: flex;
          align-items: center;
          h2 {
            font-size: 16px;
            font-family: FZLanTingHei-B-GBK;
            font-weight: bold;
            color: #f4caab;
            margin: 3vw 0 1vw 0;
          }
          .van-image {
            width: 60%;
            height: 10px;
            margin-left: -4px;
          }
          p {
            width: 100%;
            text-align: center;
            margin: 2vw auto;
            font-size: 2vw;
            font-family: FZLanTingHei-L-GBK;
            font-weight: 400;
            color: #ffffff;
            line-height: 4vw;
          }
        }
      }
    }
  }
}

.footer-box {
  width: 100%;
  background-color: #12355b;
  text-align: center;
  padding-bottom: 20px;
  padding-top: 10px;
  .bottom {
    width: 100%;
    a {
      color: #f4caab;
      font-size: 2vw;
      margin-bottom: 10px;
      display: block;
    }
  }
  .footer-list-box {
    width: 86%;
    margin: 0 auto;
    padding: 5% 2%;
    .footer-title {
      font-size: 4vw;
      color: #f4caab;
      font-weight: bold;
    }
    .footer-list {
      display: flex;
      width: 100%;
      align-items: center;
      text-align: center;
      font-size: 2vw;
      line-height: 8vw;
      color: #f4caab;
      a {
        color: #f4caab;
        display: inline-flex;
        align-items: center;
      }
      .van-icon {
        font-size: 5vw;
        margin-right: 5px;
      }

      .van-image {
        width: 40vw;
        height: 40vw;
      }
    }
    .footer-list2 {
      display: flex;
      justify-content: center;
      width: 100%;
      align-items: center;
      text-align: center;
      font-size: 2vw;
      line-height: 8vw;
      color: #f4caab;
      a {
        color: #f4caab;
        display: inline-flex;
        align-items: center;
      }
      .van-icon {
        font-size: 5vw;
        margin-right: 5px;
      }

      .van-image {
        width: 40vw;
        height: 40vw;
      }
    }
  }
}

.van-nav-bar {
  background-color: #12355b;
  color: white;
  position: fixed;
  top: 0;
  width: 100vw;
  z-index: 200;
}
</style>
<style>
.van-nav-bar__title,
.van-popup__close-icon {
  color: #f4caab;
}
.van-nav-bar .van-icon {
  color: #f4caab;
}
.van-popup__close-icon--top-left {
  top: 12px;
}
</style>
