<template>
  <div class="apply-box">
    <van-image src="https://cdn.cxc-group.com/website/development.png" fit="cover" />
    <div class="form">
      <div class="top">APP尚在开发中</div>
      <div class="bot">
        抱歉
        <br />APP尚在持续开发中
        <br />为了您的良好体验
        <br />请等待正式版上线后使用
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "MobileApply",
  data() {
    return {};
  },
  methods: {
    goHome(index) {
      localStorage.setItem("mobilePage", index);
      this.$router.push("/m/");
    }
  },
  mounted() {}
};
</script>

<style scoped lang="scss">
.apply-box {
  width: 100%;
  height: 100vh;
  background: #f1f2f3;
  padding-top: 46px;
  .van-image {
    width: 410px;
    height: 415px;
  }
  .form {
    letter-spacing: 1px;
    .top {
      width: 100%;
      margin-top: 34px;
      text-align: center;
      height: 25px;
      font-size: 18px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: rgba(0, 0, 0, 0.65);
      line-height: 15px;
    }
    .bot {
      width: 100%;
      margin-top: 26px;
      text-align: center;
      height: 25px;
      font-size: 14px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: rgba(0, 0, 0, 0.65);
      line-height: 25px;
    }
  }
}
</style>
