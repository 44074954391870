export default {
  geo: {
    map: "china", // js地图包
    type: "scatter", // 散点图
    zoom: 1.16,
    // 地图文本标签设置
    label: {
      normal: {
        show: false, // 显示市级名称
        color: "#666",
      },
      emphasis: {
        color: "#fff",
        show: false,
      },
    },
    // 地图区域设置
    itemStyle: {
      normal: {
        show: true,
        color: "#323c48", // 地图背景色
        borderColor: "#111",
        borderWidth: 0.5,
      },
      emphasis: {
        show: true,
        areaColor: "#444", // 滑过高亮颜色
        borderColor: "#ccc",
      },
    },
  },
  // title: {
  //   show: true, //显示策略，默认值true,可选为：true（显示） | false（隐藏）
  //   text: '蓝色圆点为已开通区域', //主标题文本，'\n'指定换行
  //   subtext: '黄色圆点为待开通区域', //副标题文本，'\n'指定换行
  //   x: 'right', //水平安放位置，默认为'left'，可选为：'center' | 'left' | 'right' | {number}（x坐标，单位px）
  //   y: 'bottom', //垂直安放位置，默认为top，可选为：'top' | 'bottom' | 'center' | {number}（y坐标，单位px）
  //   padding: [25, 100],
  //   textStyle: { //主标题文本样式{"fontSize": 18,"fontWeight": "bolder","color": "#333"}
  //     fontFamily: 'Arial, Verdana, sans...',
  //     fontSize: 30,
  //     fontStyle: 'normal',
  //     fontWeight: 'normal',
  //     color: "rgba(249, 247, 247, 1)"
  //   },
  //   subtextStyle: { //副标题文本样式{"color": "#aaa"}
  //     fontFamily: 'Arial, Verdana, sans...',
  //     fontSize: 30,
  //     fontStyle: 'normal',
  //     fontWeight: 'normal',
  //     color: "rgba(249, 247, 247, 1)"
  //   },
  // },
  visualMap: {
    min: 0, //最小
    max: 300, //最大
    splitNumber: 5, //共分5层
    color: ["#ff6300", "#eac736", "rgb(0,145,255)"], //颜色从高到低依次渐变
    textStyle: {
      color: "#fff",
    },
    show: false,
  },
  series: [{
    name: "起始涟漪城市",
    type: "effectScatter",
    coordinateSystem: "geo",
    zlevel: 2,
    symbol: "circle", // 标记的图形。
    symbolSize: 10, // 标记的大小。
    rippleEffect: {
      period: 4, // 动画速度，值越小，动画越快
      brushType: "stroke", // 波纹的绘制方式
      scale: 6.5,
    },
    // 涟漪文字效果
    label: {
      normal: {
        show: true,
        // 提示内容
        formatter: (params) => {
          return params.name;
        },
        position: "right", // 提示方向
        color: "#fff",
      },
      emphasis: {
        show: true, // 点
        color: "#fff",
      },
    },
    itemStyle: {
      normal: {
        color: "rgb(0,145,255)",
        label: {
          textStyle: {
            fontSize: 15,
          },
        },
      },
    },
    data: [{
        name: "上海", // 城市name
        value: [121.445, 31.213, 50], // 城市坐标 前两个经纬度 第三个value值
      },
      {
        name: "浙江",
        value: [120.2, 29.26667, 50],
      },
      {
        name: "江苏",
        value: [119.78, 33.04, 50],
      },
      {
        name: "西藏",
        value: [87.8, 31.04, 280],
      },
      {
        name: "四川",
        value: [103.03, 30.34, 280],
      },
      {
        name: "青海",
        value: [96.26, 35.61, 280],
      },
      {
        name: "广东",
        value: [113.22, 23.82, 280],
      },
      {
        name: "云南",
        value: [101.56, 24.25, 280],
      },
      {
        name: "甘肃",
        value: [104.58, 34.52, 280],
      },
      {
        name: "福建",
        value: [118.04, 25.5, 280],
      },
    ],
  }, ],
};