<template>
  <div class="about">
    <Header></Header>
    <div class="box" style="margin-top: 63px;">
      <div class="title">
        <img src="https://cdn.cxc-group.com/website/new/53.png" class="title1" />
        <div class="clear"></div>
        <img src="https://cdn.cxc-group.com/website/new/54.png" class="title2" />
        <div class="title3">
          <img src="https://cdn.cxc-group.com/website/new/12.png" class="left" />
          <div class="intro">
            小缘文化深耕互联网领域十余载，研发可量产的智能硬件终端，
            <br />打造一体化行业解决方案，为“更智慧，更满意”赋能
          </div>
          <img src="https://cdn.cxc-group.com/website/new/13.png" class="right" />
        </div>
      </div>
    </div>
    <div class="box1">
      <div class="title">
        <img src="https://cdn.cxc-group.com/website/new/55.png" class="title1" />
        <div class="title2">
          <div
            class="intro"
          >杭州小缘文化科技有限公司成立于2020年9月11日。公司以，软硬产品研发为核心，打造基于O2O体系的智慧服务系统，让管理便捷、高效、安全、精准！</div>
          <img src="https://cdn.cxc-group.com/website/new/54.png" class="icon" />
        </div>
      </div>
      <div class="lists">
        <div class="list">
          <img src="https://cdn.cxc-group.com/website/new/56.png" class="img" />
          <div class="top1"></div>
          <div class="bottom">
            <img src="https://cdn.cxc-group.com/website/new/59.png" class="line" />
            <div class="word">服务与企业，共结今世善缘</div>
            <img src="https://cdn.cxc-group.com/website/new/59.png" class="line1" />
          </div>
        </div>
        <div class="list">
          <img src="https://cdn.cxc-group.com/website/new/57.png" class="img" />
          <div class="top2"></div>
          <div class="bottom">
            <img src="https://cdn.cxc-group.com/website/new/59.png" class="line" />
            <div class="word">成为服务平台领先品牌</div>
            <img src="https://cdn.cxc-group.com/website/new/59.png" class="line1" />
          </div>
        </div>
        <div class="list">
          <img src="https://cdn.cxc-group.com/website/new/58.png" class="img" />
          <div class="top3"></div>
          <div class="bottom">
            <img src="https://cdn.cxc-group.com/website/new/59.png" class="line" />
            <div class="word">诚信、平等、圆融、共赢</div>
            <img src="https://cdn.cxc-group.com/website/new/59.png" class="line1" />
          </div>
        </div>
      </div>
    </div>
    <Footer></Footer>
  </div>
</template>
<script>
// @ is an alias to /src
import Header from "@/components/header.vue";
import Footer from "@/components/footer.vue";

export default {
  name: "Home",
  components: {
    Header,
    Footer
  },
  data() {
    return {
      isActive: 1
    };
  },
  mounted() {},
  methods: {
    equip(e) {
      console.log(e);
      this.isActive = e;
    }
  }
};
</script>
<style lang="scss" scoped>
* {
  margin: 0;
  padding: 0;
}
.box {
  background: url("https://cdn.cxc-group.com/website/new/52.png");
  background-size: 100%;
  background-repeat: no-repeat;
  position: relative;
  min-height: 570px;
  .title {
    width: 100%;
    position: absolute;
    left: 0%;
    top: 89px;
    .title1 {
      width: 45%;
    }
    .clear {
      clear: both;
    }
    .title2 {
      font-size: 35px;
      margin-top: 43px;
      color: #fff;
      width: 410px;
    }
    .title3 {
      width: 1000px;
      margin: 0 auto;
      font-size: 18px;
      margin-top: 56px;
      letter-spacing: 1px;
      display: flex;
      justify-content: space-around;
      color: #fff;
      .left {
        width: 100px;
        height: 18px;
      }
      .right {
        width: 100px;
        height: 18px;
      }
    }
  }
}
.box1 {
  .title {
    width: 100%;
    margin: 0 auto;
    .title1 {
      width: 280px;
      padding: 65px 24px 0px 24px;
    }
    .title2 {
      width: 80%;
      margin: 0 auto;
      margin-top: 56px;
      letter-spacing: 1px;
      .intro {
        width: 660px;
        font-size: 18px;
        margin: 0 auto;
        font-weight: 400;
        color: #000000;
        line-height: 32px;
      }
      .icon {
        width: 410px;
        margin-top: 81px;
      }
    }
  }
  .lists {
    padding-top: 82px;
    width: 838px;
    margin: 0 auto 150px auto;
    display: flex;
    justify-content: space-between;
    .list {
      height: 393px;
      width: 266px;
      position: relative;
      .img {
        position: absolute;
        width: 211px;
        height: 75px;
        left: 27.5px;
        top: -37.5px;
      }
      .top1 {
        width: 266px;
        height: 233px;
        background: url("https://cdn.cxc-group.com/website/new/60.png");
        background-size: 266px;
        background-repeat: no-repeat;
      }
      .top2 {
        width: 266px;
        height: 233px;
        background: url("https://cdn.cxc-group.com/website/new/61.png");
        background-size: 266px;
        background-repeat: no-repeat;
      }
      .top3 {
        width: 266px;
        height: 233px;
        background: url("https://cdn.cxc-group.com/website/new/62.png");
        background-size: 266px;
        background-repeat: no-repeat;
      }
      .bottom {
        width: 266px;
        height: 156px;
        background: #12355b;
        border-bottom-left-radius: 15px;
        border-bottom-right-radius: 15px;
        .line {
          width: 150px;
          margin-top: 46px;
        }
        .word {
          font-size: 14px;
          margin-top: 11px;
          color: #fff;
        }
        .line1 {
          width: 150px;
          margin-top: 15px;
        }
      }
    }
  }
}
</style>
