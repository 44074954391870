<template>
  <div class="test-niche-container">
<!--    <div class="title">我的祈福</div>-->
    <div class="box">
      <van-image class="img" style="width: 100vw;height: 95vh" src="https://cdn.cxc-group.com/website/qf.png" fit="cover" />
      <van-image class="img2" style="width: 100vw;height: 95vh" src="https://cdn.cxc-group.com/website/qf-light.png" fit="cover" />
      <div class="content">{{content}}</div>
    </div>
  </div>
</template>

<script>
export default {
  name: "TestNiche",
  data() {
    return {
      content:
        ""
    };
  },
  methods: {},
  mounted() {
    this.content = localStorage.getItem('lamp_bless_text')
  }
};
</script>
<style scoped lang="scss">
.test-niche-container {
  box-sizing: border-box;
  width: 100vw;
  min-height: 100vh;
  background: linear-gradient(180deg, #241c0b 0%, #756b61 100%);
  position: relative;
  .title {
    font-size: 7vw;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #ffffff;
    letter-spacing: 2px;
    text-align: left;
    top: 12vw;
    left: 5%;
    position: absolute;
  }
  .box {
    position: relative;
    .img2 {
      position: absolute;
      top: 0;
      left: 0;
      animation-name: drive;
      animation-duration: 2s;
      animation-timing-function: ease-in-out;
    }
    @keyframes drive {
      from {
        opacity: 0;
      }

      to {
        opacity: 1;
      }
    }
    .content {
      position: absolute;
      bottom: 16vh;
      left: 25%;
      font-size: 10px;
      line-height: 22px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #ffffff;
      width: 50%;
    }
  }
}
</style>
