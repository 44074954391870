<template>
  <div class="home" ref="boxView">
    <div class="header">
      <div class="logo">
        <img src="https://cdn.cxc-group.com/website/web-logo.png" />
      </div>
      <div class="content">
        <div>
          <a href="javascript:;" @click="jump(0)">首页</a>
          <span></span>
        </div>
        <div>
          <a href="javascript:;" @click="jump(1)">小缘系统</a>
          <span></span>
        </div>
        <!-- <div>
          <a href="javascript:;" @click="jump(2)">产品中心</a>
          <span></span>
        </div> -->
        <div>
          <a href="javascript:;" @click="jump(3)">服务支持</a>
          <span></span>
        </div>
        <div @click="about()">
          关于小缘
          <span></span>
        </div>
      </div>
    </div>
    <div class="bar1 bar">
      <!-- <div class="banner">
        <el-carousel :interval="300000" type height="600px" trigger="hover">
          <el-carousel-item v-for="(image, index) in images" :key="index">
            <div style="position:relative;width:1131px;margin:0 auto;">
              <img :src="image.url" style="width:1131px;margin-top:30px;" />
              <img :src="image.small" style="position:absolute;top:80px;left:415px;width:300px;" />
              <img :src="image.base" style="position:absolute;top:290px;left:365px;width:400px;" />
              <img :src="image.large" style="position:absolute;top:350px;left:265px;width:600px;" />
            </div>
          </el-carousel-item>
        </el-carousel>
      </div> -->
      <!-- <div class="page1">
        <div class="title">
          <img src="https://cdn.cxc-group.com/website/new/4.png" class="title1" />
          <div class="title2">
            <img src="https://cdn.cxc-group.com/website/new/12.png" class="left" />
            <div class="intro">小缘始终致力于智慧寺院的系统与产品研发</div>
            <img src="https://cdn.cxc-group.com/website/new/13.png" class="right" />
          </div>
        </div>
        <div class="list">
          <div class="list1">
            <img src="https://cdn.cxc-group.com/website/new/5.png" class="img1" />
            <img src="https://cdn.cxc-group.com/website/new/28.png" class="img2" />
            <div class="content">
              <div class="one">小缘智慧平台</div>
              <img src="https://cdn.cxc-group.com/website/new/10.png" class="img3" />
              <div class="two">
                接入多个系统，大数据，
                <br />核心算法， 管理更便捷
              </div>
            </div>
          </div>
          <div class="list1">
            <img src="https://cdn.cxc-group.com/website/new/6.png" class="img1" />
            <img src="https://cdn.cxc-group.com/website/new/29.png" class="img2" />
            <div class="content">
              <div class="one">殿堂方案设计</div>
              <img src="https://cdn.cxc-group.com/website/new/10.png" class="img3" />
              <div class="two">
                面向不同寺院殿堂的需求，提供深
                <br />度的智慧殿堂方案，满足核心诉求
              </div>
            </div>
          </div>
          <div class="list1">
            <img src="https://cdn.cxc-group.com/website/new/7.png" class="img1" />
            <img src="https://cdn.cxc-group.com/website/new/30.png" class="img2" />
            <div class="content">
              <div class="one">智能硬件开发</div>
              <img src="https://cdn.cxc-group.com/website/new/10.png" class="img3" />
              <div class="two">
                定制化开发适合寺院所
                <br />需求的产品，实现数据科技采集
              </div>
            </div>
          </div>
          <div class="list1">
            <img src="https://cdn.cxc-group.com/website/new/8.png" class="img1" />
            <img src="https://cdn.cxc-group.com/website/new/31.png" class="img2" />
            <div class="content">
              <div class="one">项目落地实施</div>
              <img src="https://cdn.cxc-group.com/website/new/10.png" class="img3" />
              <div class="two">
                完善的项目实施与售后体系，
                <br />推动智慧寺院全国落地
              </div>
            </div>
          </div>
        </div>
      </div> -->
      <div class="page2">
        <div class="title">
          <div class="title1">科技改变世界，智慧链接万物</div>
          <img src="https://cdn.cxc-group.com/website/new/10.png" class="img1" />
          <div class="title2">
            <img src="https://cdn.cxc-group.com/website/new/12.png" class="left" />
            <div class="intro">小缘以O2O的模式，为更多的企业带来可持续的创新价值</div>
            <img src="https://cdn.cxc-group.com/website/new/13.png" class="right" />
          </div>
        </div>
        <div class="list">
          <div class="list1">
            <img src="https://cdn.cxc-group.com/website/new/32.png" class="img1" />
            <div class="content">采集</div>
            <div class="content2">多智能终端，多维度精准收集</div>
          </div>
          <div class="list1">
            <img src="https://cdn.cxc-group.com/website/new/33.png" class="img1" />
            <div class="content">分析</div>
            <div class="content2">后台人工智能全天24小时解析处理</div>
          </div>
          <div class="list1">
            <img src="https://cdn.cxc-group.com/website/new/34.png" class="img1" />
            <div class="content">下发</div>
            <div class="content2">精准、智能、高效、针对性投射</div>
          </div>
        </div>
      </div>
      <!-- <div class="page3">
        <van-image
          width="610"
          height="70"
          src="https://cdn.cxc-group.com/website/new/14.png"
          fit="contain"
          class="img1"
        />
        <img src class="img1" />
        <div class="list">
          <div class="listBox">
            <img src="https://cdn.cxc-group.com/website/new/35.png" class="img2" />
            <div class="title1">线上祈福</div>
            <div class="title2">线下同步</div>
          </div>
          <div class="listBox">
            <img src="https://cdn.cxc-group.com/website/new/36.png" class="img2" />
            <div class="title1">线上预约</div>
            <div class="title2">法会不缺席，佛节不缺席</div>
          </div>
          <div class="listBox">
            <img src="https://cdn.cxc-group.com/website/new/37.png" class="img2" />
            <div class="title1">线上祭奠</div>
            <div class="title2">选择供奉，线下同步供奉实物</div>
          </div>
        </div>
      </div> -->
    </div>

    <div class="bar2 bar">
      <!-- <div class="page4">
        <div class="title">
          <img src="https://cdn.cxc-group.com/website/new/16.png" class="img1" />
          <div class="title2">
            <img src="https://cdn.cxc-group.com/website/new/12.png" class="left" />
            <div class="intro">基于阿里云强大的基础资源</div>
            <img src="https://cdn.cxc-group.com/website/new/13.png" class="right" />
          </div>
        </div>
        <div class="list">
          <div class="list1">
            <div class="list-bottom">
              <div class="top">接设备</div>
              <div class="content" style="width:143px;">佛龛，莲花灯，蜡烛，牌位， 荷花台等多入口</div>
            </div>
          </div>
          <div class="list1">
            <div class="list-bottom">
              <div class="top">管设备</div>
              <div class="content" style="width:167px;">PC端，IPAD,手机等多终端管理</div>
            </div>
          </div>
          <div class="list1">
            <div class="list-bottom">
              <div class="top">采数据</div>
              <div class="content" style="width:109px;">后台服务器云计算， 物联网，精准采集</div>
            </div>
          </div>
          <div class="list1">
            <div class="list-bottom">
              <div class="top">供服务</div>
              <div class="content" style="width:136px;">线上线下一站式O2O服务， 不断提高效率</div>
            </div>
          </div>
          <div class="list1">
            <div class="list-bottom">
              <div class="top">云控台</div>
              <div class="content" style="width:190px;">采用阿里高端服务器，大规模分布式计算机，数万笔/秒级并发能力，严格保证数据统一性</div>
            </div>
          </div>
        </div>
      </div> -->
      <div class="page1">
        <div class="title">
          <img src="https://cdn.cxc-group.com/website/new/17.png" class="title1" />
        </div>
        <div class="list">
          <div class="list1">
            <img src="https://cdn.cxc-group.com/website/new/39.png" class="img1" />
            <div class="content">小缘助手</div>
            <div class="content2">
              数字化管理
              <!-- <br />智能殿堂与僧众管理 -->
            </div>
          </div>
          <div class="list1">
            <img src="https://cdn.cxc-group.com/website/new/40.png" class="img1" />
            <div class="content">小缘商城</div>
            <div class="content2">
              严选供应链
              <!-- <br />提供优质的佛事用品 -->
            </div>
          </div>
          <div class="list1">
            <img src="https://cdn.cxc-group.com/website/new/41.png" class="img1" />
            <div class="content">小缘圈子</div>
            <div class="content2">
              社交一体化
              <!-- <br />禅文化交流圈 -->
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- <div class="bar3 bar">
      <div class="title">
        <img src="https://cdn.cxc-group.com/website/new/18.png" class="img1" />
      </div>
      <div class="equip">
        <div class="lists">
          <div class="list" @click="equip(1)" :class="{ active: isActive == 1 }">佛龛</div>
          <div class="list" @click="equip(2)" :class="{ active: isActive == 2 }">莲花灯</div>
          <div class="list" @click="equip(3)" :class="{ active: isActive == 3 }">烛台</div>
          <div class="list" @click="equip(4)" :class="{ active: isActive == 4 }">蜡烛灯</div>
          <div class="list" @click="equip(5)" :class="{ active: isActive == 5 }">牌位</div>
          <div class="list" @click="equip(6)" :class="{ active: isActive == 6 }">荷花台</div>
        </div>
        <div class="img" v-if="isActive == 1">
          <el-carousel :interval="3000" type height="443px" trigger="hover">
            <el-carousel-item v-for="(image, index) in img" :key="index">
              <img :src="image.url" style="width:877px;" />
            </el-carousel-item>
          </el-carousel>
        </div>
        <div class="img" v-if="isActive == 2">
          <el-carousel :interval="3000" type height="443px" trigger="hover">
            <el-carousel-item v-for="(image, index) in img2" :key="index">
              <img :src="image.url" style="width:877px;" />
            </el-carousel-item>
          </el-carousel>
        </div>
        <div class="img" v-if="isActive == 3">
          <el-carousel :interval="3000" type height="443px" trigger="hover">
            <el-carousel-item v-for="(image, index) in img3" :key="index">
              <img :src="image.url" style="width:877px;" />
            </el-carousel-item>
          </el-carousel>
        </div>
        <div class="img" v-if="isActive == 4">
          <el-carousel :interval="3000" type height="443px" trigger="hover">
            <el-carousel-item v-for="(image, index) in img4" :key="index">
              <img :src="image.url" style="width:877px;" />
            </el-carousel-item>
          </el-carousel>
        </div>
        <div class="img" v-if="isActive == 5">
          <el-carousel :interval="3000" type height="443px" trigger="hover">
            <el-carousel-item v-for="(image, index) in img5" :key="index">
              <img :src="image.url" style="width:877px;" />
            </el-carousel-item>
          </el-carousel>
        </div>
        <div class="img" v-if="isActive == 6">
          <el-carousel :interval="3000" type height="443px" trigger="hover">
            <el-carousel-item v-for="(image, index) in img6" :key="index">
              <img :src="image.url" style="width:877px;" />
            </el-carousel-item>
          </el-carousel>
        </div>
      </div>
    </div> -->
    <div class="bar5 bar">
      <div class="page">
        <div class="content">
          <div class="serve">
            <img src="https://cdn.cxc-group.com/website/new/43.png" class="title1" />
            <div class="intro">
              针对各类应用场景，规模
              <br />小缘团队提供全方位的服务支持方案，保障项目落地实施与运行维护
            </div>
          </div>
          <img src="https://cdn.cxc-group.com/website/new/22.png" class="title2" />

          <div class="list">
            <div class="list1">
              <img src="https://cdn.cxc-group.com/website/new/5.png" class="img1" />
              <img src="https://cdn.cxc-group.com/website/new/44.png" class="img2" />
              <div class="content">
                <div class="one">商品下单</div>
              </div>
            </div>
            <div class="list1">
              <img src="https://cdn.cxc-group.com/website/new/6.png" class="img1" />
              <img src="https://cdn.cxc-group.com/website/new/45.png" class="img2" />
              <div class="content">
                <div class="one">预约施工</div>
              </div>
            </div>
            <div class="list1">
              <img src="https://cdn.cxc-group.com/website/new/7.png" class="img1" />
              <img src="https://cdn.cxc-group.com/website/new/46.png" class="img2" />
              <div class="content">
                <div class="one">上门服务</div>
              </div>
            </div>
            <div class="list1">
              <img src="https://cdn.cxc-group.com/website/new/8.png" class="img1" />
              <img src="https://cdn.cxc-group.com/website/new/47.png" class="img2" />
              <div class="content">
                <div class="one">验收测试</div>
              </div>
            </div>
            <div class="list1">
              <img src="https://cdn.cxc-group.com/website/new/9.png" class="img1" />
              <img src="https://cdn.cxc-group.com/website/new/48.png" class="img2" />
              <div class="content">
                <div class="one">售后维护</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="bar4 bar">
      <div class="title1">全国范围内的服务网络</div>
      <div class="title2">线下服务商体系的不断完善，让我们拥有更多合作</div>
      <div class="intro">
        <div class="top">
          <span class="blue"></span>
          <div>蓝色圆点为已开通区域</div>
        </div>
        <div class="top">
          <span class="yellow"></span>
          <div>黄色圆点为待开通区域</div>
        </div>
      </div>
      <div id="main" class="echart" style="width: 100%;height: 700px;"></div>
    </div>
    <div class="footer">
      <div class="top">
        <div class="left">
          <div class="list">
            <a href="javascript:;" @click="jump(0)" style="font-size:20px;font-weight:bolder;">小缘首页</a>
          </div>
          <div class="list">
            <a href="javascript:;" @click="jump(1)">小缘系统</a>
          </div>
          <div class="list">
            <a href="javascript:;" @click="jump(2)">产品中心</a>
          </div>
          <div class="list">
            <a href="javascript:;" @click="jump(3)">服务支持</a>
          </div>
          <div class="list" @click="about()">关于小缘</div>
        </div>
        <div class="center">
          <div class="list" style="font-size:20px;font-weight:bolder;">商务合作</div>
          <div class="list">如果您有商务合作的需要请联系我们</div>
          <div class="list">电话：0571-87766335</div>
          <div class="list">邮箱：postmaster@cxc-group.com</div>
          <div class="list">地址：杭州市西湖区三墩镇留祥路西行桥74号丰瑞大厦B幢401室</div>
        </div>
        <div class="right">
          <div style="height:40px;line-height:40px;font-weight:bolder;font-size:20px;">关注我们</div>
          <img src="https://cdn.cxc-group.com/website/ewm.jpg" class="serve" />
          <div style="height:40px;line-height:40px;margin-top:-20px;">微信扫一扫关注我们</div>
        </div>
      </div>
      <div class="bottom">
        <a class="copyright" href="https://beian.miit.gov.cn/">Copyright 2020 杭州小缘文化科技有限公司 版权所有</a>
        <a
          class="copyright"
          target="_blank"
          href="https://beian.miit.gov.cn"
          style="display:inline-block;text-decoration:none;height:20px;line-height:20px;"
        >
          <img src="https://cdn.cxc-group.com/website/footer.png" style="float:left;" />
          <p
            style="float:left;height:20px;line-height:20px;margin: 0px 0px 0px 5px; color:#939393;"
          >浙ICP备2020036517号</p>
        </a>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import "../../node_modules/echarts/map/js/china.js";
import optionMap from "../../public/map.js";
import echarts from "echarts";
export default {
  name: "Home",
  components: {},
  data() {
    return {
      isActive: 1,
      active: 0,
      images: [
        {
          url: "https://cdn.cxc-group.com/website/new/banner1-bg.jpg",
          small: "https://cdn.cxc-group.com/website/new/banner1-title.png",
          base: "https://cdn.cxc-group.com/website/new/3.png",
          large: "https://cdn.cxc-group.com/website/new/banner1-text.png"
        },
        { url: "https://cdn.cxc-group.com/website/new/51.png" }
      ],
      img: [
        {
          url: "https://cdn.cxc-group.com/website/new/fk1.jpg"
        },
        { url: "https://cdn.cxc-group.com/website/new/fk2.jpg" }
      ],
      img2: [
        {
          url: "https://cdn.cxc-group.com/website/new/lhd.jpg"
        }
      ],
      img3: [
        {
          url: "https://cdn.cxc-group.com/website/new/zt.jpg"
        }
      ],
      img4: [
        {
          url: "https://cdn.cxc-group.com/website/new/lzd.jpg"
        }
      ],
      img5: [
        {
          url: "https://cdn.cxc-group.com/website/new/pw.jpg"
        }
      ],
      img6: [
        {
          url: "https://cdn.cxc-group.com/website/new/hht.jpg"
        }
      ]
    };
  },
  mounted() {
    this.$nextTick(function() {
      window.addEventListener("scroll", this.onScroll);
    });
    this.initEchart();
  },
  methods: {
    equip(e) {
      console.log(e);
      this.isActive = e;
    },
    about() {
      this.$router.push({ name: "About" });
    },
    jump(index) {
      // 用 class="instance_title" 添加锚点
      let jump = document.querySelectorAll(".bar");
      let total = jump[index].offsetTop - 84;
      let distance =
        document.documentElement.scrollTop || document.body.scrollTop;
      // 平滑滚动，时长500ms，每10ms一跳，共50跳
      let step = total / 50;
      if (total > distance) {
        smoothDown();
      } else {
        let newTotal = distance - total;
        step = newTotal / 50;
        smoothUp();
      }
      function smoothDown() {
        if (distance < total) {
          distance += step;
          document.body.scrollTop = distance;
          document.documentElement.scrollTop = distance;
          setTimeout(smoothDown, 10);
        } else {
          document.body.scrollTop = total;
          document.documentElement.scrollTop = total;
        }
      }
      function smoothUp() {
        if (distance > total) {
          distance -= step;
          document.body.scrollTop = distance;
          document.documentElement.scrollTop = distance;
          setTimeout(smoothUp, 10);
        } else {
          document.body.scrollTop = total;
          document.documentElement.scrollTop = total;
        }
      }
    },

    // onScroll() {
    //   let scrolled =
    //     document.documentElement.scrollTop || document.body.scrollTop; // 586、1063分别为第二个和第三个锚点对应的距离
    //   if (scrolled >= 1063) {
    //     this.steps.active = 2;
    //   } else if (scrolled < 1063 && scrolled >= 586) {
    //     this.steps.active = 1;
    //   } else {
    //     this.steps.active = 0;
    //   }
    // }

    initEchart() {
      var mapchart = echarts.init(document.getElementById("main"));
      mapchart.setOption(optionMap);
      window.onresize = function() {
        // resizeMyChartContainer();
        mapchart.resize();
      };
    }
  }
};
</script>
<style lang="scss">
body {
  margin: 0;
  padding: 0;
}
.m30 {
  margin-top: 30px;
}
.fs50 {
  font-size: 50px;
}
.fs30 {
  font-size: 30px;
}
.fs18 {
  font-size: 18px;
}
.white {
  color: #fff;
}
.header {
  font-family: STHeiti;
  display: flex;
  justify-content: space-between;
  background: #12355b;
  position: fixed;
  height: 80px;
  left: 0;
  top: 0;
  width: 100%;
  z-index: 40000;
  .logo {
    width: 20%;
    margin-left: 4%;
    img {
      width: 150px;
      margin-top: 10px;
    }
  }
  .content {
    width: 54%;
    // margin-left: 20%;
    display: flex;
    justify-content: space-around;
    margin-right: 8%;
    font-size: 18px;
    div {
      width: 20%;
      height: 80px;
      line-height: 80px;
      color: #fff;
      cursor: pointer;
      a {
        display: block;
        width: 100%;
        height: 80px;
        line-height: 80px;
        text-decoration: none;
        color: #fff;
      }
    }
    div:hover {
      color: #fff;
      background: #2f54eb;
      a {
        color: #fff;
        background: #2f54eb;
      }
    }
  }
}
.bar1 {
  font-family: STHeiti;
  margin-top: 80px;
  width: 100%;
  .banner {
    width: 100%;
    height: 481px;
  }
  .page1 {
    width: 100%;
    .title {
      width: 760px;
      margin: 0 auto;
      .title1 {
        width: 280px;
        padding: 65px 24px 0px 24px;
      }
      .title2 {
        width: 80%;
        margin: 0 auto;
        font-size: 18px;
        font-weight: 500;
        margin-top: 56px;
        letter-spacing: 1px;
        display: flex;
        justify-content: space-between;
        .left {
          width: 100px;
          height: 18px;
        }
        .intro {
          line-height: 18px;
          height: 18px;
        }
        .right {
          width: 100px;
          height: 18px;
        }
      }
      .title3 {
        font-size: 35px;
        margin-top: 50px;
      }
    }
    .list {
      width: 1131px;
      margin: 100px auto 70px auto;
      text-align: center;
      display: flex;
      justify-content: space-between;
      .list1 {
        font-size: 24px;
        // width: 21%;
        position: relative;
        width: 266px;
        height: 392px;
        border-radius: 20px;
        .img1 {
          width: 25%;
          display: flex;
          justify-content: center;
          margin-left: 30%;
          margin-bottom: 10px;
          position: absolute;
          top: -30px;
          left: 50%;
          margin-left: -12.5%;
          z-index: 20000;
        }
        .img2 {
          width: 266px;
          display: flex;
          justify-content: center;
          position: absolute;
          top: 0;
          z-index: 10000;
          left: 0;
          border-top-left-radius: 20px;
          border-top-right-radius: 20px;
        }
        .content {
          width: 266px;
          height: 156px;
          position: absolute;
          top: 232px;
          left: 0;
          background: #12355b;
          color: #fff;
          border-bottom-left-radius: 20px;
          border-bottom-right-radius: 20px;
          font-family: STHeiti;
          .img3 {
            width: 148px;
            margin: 10px auto;
          }
          .one {
            font-size: 23px;
            margin-top: 22px;
            font-weight: 400;
            color: #f4caab;
          }
          .two {
            font-size: 14px;
            line-height: 21px;
          }
        }
      }
    }
  }
  .page2 {
    background-image: linear-gradient(
      rgba(255, 217, 169, 0.1),
      rgba(221, 144, 65, 0.1)
    );
    margin-top: 70px;
    height: 580px;
    .title {
      width: 760px;
      margin: 0 auto;
      .title1 {
        font-size: 35px;
        padding: 65px 24px 10px 24px;
      }
      .title2 {
        width: 100%;
        margin: 0 auto;
        font-size: 18px;
        margin-top: 45px;
        letter-spacing: 1px;
        display: flex;
        justify-content: space-between;
        .left {
          width: 100px;
          height: 18px;
        }
        .intro {
          height: 18px;
          line-height: 18px;
        }
        .right {
          width: 100px;
          height: 18px;
        }
      }
      .img1 {
        width: 187px;
        margin: 17px auto;
      }
    }
    .list {
      width: 80%;
      margin: 66px auto 70px auto;
      text-align: center;
      display: flex;
      justify-content: space-around;
      .list1 {
        width: 250px;
        img {
          width: 136px;
          height: 137px;
          display: flex;
          justify-content: center;
          margin-left: 57px;
          margin-bottom: 10px;
        }
        .content {
          margin-top: 22px;
          font-size: 23px;
          font-family: STHeiti;
          font-weight: 400;
          color: #14365d;
        }
        .content2 {
          margin-top: 22px;
          font-size: 14px;
          font-family: STHeiti;
          color: #666666;
        }
      }
    }
  }
  .page3 {
    width: 100%;
    height: 685px;
    background-image: url("https://cdn.cxc-group.com/website/new/15.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    .img1 {
      margin: 87px auto;
    }
    .list {
      width: 80%;
      margin: 0 auto;
      display: flex;
      justify-content: space-around;
      .listBox {
        color: #fff;
        .img2 {
          width: 100px;
        }
        .title1 {
          font-size: 23px;
          margin-top: 30px;
          font-weight: bolder;
          text-align: center;
        }
        .title2 {
          font-size: 14px;
          margin-top: 20px;
          text-align: center;
        }
      }
    }
  }
}

.bar2 {
  width: 100%;
  font-size: 20px;
  font-family: STHeiti;
  .page1 {
    width: 100%;
    margin-top: 65px;
    height: 580px;
    background-image: linear-gradient(
      rgba(255, 217, 169, 0.1),
      rgba(221, 144, 65, 0.1)
    );
    .title {
      width: 50%;
      margin: 0 auto;
      .title1 {
        width: 165px;
        font-weight: bolder;
        padding: 94px 24px 0 24px;
      }
      .title2 {
        font-size: 18px;
      }
      .title3 {
        font-size: 35px;
        margin-top: 50px;
      }
    }
    .list {
      width: 80%;
      margin: 66px auto 70px auto;
      text-align: center;
      display: flex;
      justify-content: space-around;
      .list1 {
        width: 250px;
        img {
          width: 136px;
          height: 137px;
          display: flex;
          justify-content: center;
          margin-left: 57px;
          margin-bottom: 10px;
        }
        .content {
          margin-top: 22px;
          font-size: 23px;
          font-family: STHeiti;
          font-weight: 400;
          color: #14365d;
        }
        .content2 {
          width: 132px;
          margin: 0 auto;
          margin-top: 22px;
          font-size: 14px;
          line-height: 21px;
          font-family: STHeiti;
          color: #666666;
        }
      }
    }
  }
  .page4 {
    width: 100%;
    // height: 490px;
    .title {
      width: 600px;
      margin: 0 auto;
      .img1 {
        width: 165px;
        padding: 65px 24px 0 24px;
      }
      .title2 {
        width: 80%;
        margin: 0 auto;
        font-size: 18px;
        margin-top: 56px;
        letter-spacing: 1px;
        display: flex;
        justify-content: space-between;
        .left {
          width: 100px;
          height: 18px;
        }
        .intro {
          height: 18px;
          line-height: 18px;
        }
        .right {
          width: 100px;
          height: 18px;
        }
      }
    }
    .list {
      width: 1130px;
      margin: 0 auto;
      text-align: left;
      display: flex;
      justify-content: flex-start;
      flex-wrap: wrap;
      background: url("https://cdn.cxc-group.com/website/new/38.jpg");
      background-size: 1130px;
      background-repeat: no-repeat;
      margin-top: 65px;
      .list1 {
        width: 226px;
        height: 410px;
        margin-top: 56px;
        font-size: 23px;
        color: #fff;
        .list-bottom {
          height: 186px;
          margin-top: 224px;
          background-image: linear-gradient(
            rgba(10, 10, 10, 0.1),
            rgba(10, 10, 10, 0.9)
          );
          .top {
            padding: 57px 0 17px 27px;
          }
          .content {
            padding-left: 27px;
            font-size: 11px;
            line-height: 21px;
          }
        }
      }
    }
  }
}
.bar3 {
  font-family: STHeiti;
  width: 100%;
  .title {
    width: 50%;
    margin: 0 auto;
    .img1 {
      width: 165px;
      padding: 72px 24px 0px 24px;
    }
  }
  .equip {
    width: 1131px;
    display: flex;
    justify-content: space-between;
    padding-top: 85px;
    margin: 0 auto;
    margin-bottom: 85px;
    .lists {
      width: 200px;
      font-size: 23px;
      .list {
        width: 200px;
        height: 60px;
        line-height: 60px;
        background-image: url("https://cdn.cxc-group.com/website/new/20.png");
        background-size: 200px 60px;
        color: #fff;
        margin-bottom: 16px;
      }
      .active {
        background-image: url("https://cdn.cxc-group.com/website/new/19.png");
      }
    }
    .img {
      width: 877px;
    }
  }
}
.bar5 {
  width: 100%;
  // height: 660px;
  font-family: STHeiti;
  .content {
    width: 100%;
    .serve {
      background-image: url("https://cdn.cxc-group.com/website/new/42.jpg");
      background-repeat: no-repeat;
      background-size: 100%;
      width: 100%;
      padding-bottom: 134px;

      .title1 {
        width: 165px;
        padding: 94px 24px 0px 24px;
      }
      .intro {
        width: 570px;
        margin: 65px auto 0 auto;
        text-align: center;
        font-size: 18px;
        line-height: 30px;
        color: #fff;
      }
    }
    .title2 {
      width: 165px;
      padding: 72px 24px 0px 24px;
    }
    .list {
      width: 1131px;
      margin: 100px auto 70px auto;
      text-align: center;
      display: flex;
      justify-content: space-between;
      .list1 {
        font-size: 24px;
        position: relative;
        width: 202px;
        height: 324px;
        border-radius: 20px;
        .img1 {
          width: 25%;
          display: flex;
          justify-content: center;
          margin-left: 30%;
          margin-bottom: 10px;
          position: absolute;
          top: -20px;
          left: 50%;
          margin-left: -10%;
          z-index: 20000;
        }
        .img2 {
          width: 202px;
          display: flex;
          justify-content: center;
          margin-bottom: 10px;
          position: absolute;
          top: 0;
          z-index: 10000;
          left: 0;
          border-top-left-radius: 20px;
          border-top-right-radius: 20px;
        }
        .content {
          width: 202px;
          height: 68px;
          position: absolute;
          top: 226px;
          left: 0;
          background: #12355b;
          color: #fff;
          border-bottom-left-radius: 20px;
          border-bottom-right-radius: 20px;
          .one {
            font-size: 23px;
            margin-top: 20px;
            font-family: STHeiti;
            font-weight: 400;
            color: #fff;
          }
        }
      }
    }
  }
}
.bar4 {
  font-family: STHeiti;
  background: #404a59;
  color: #fff;
  position: relative;
  .title1 {
    font-size: 35px;
    padding-top: 100px;
  }
  .title2 {
    margin-top: 10px;
    font-size: 18px;
  }
  .echart {
    margin-top: 50px;
    padding-bottom: 50px;
  }
  .intro {
    position: absolute;
    bottom: 50px;
    left: 100px;
    color: #fff;
    .top {
      display: flex;
      justify-content: start;
      align-items: center;
      div {
        line-height: 30px;
        font-size: 14px;
        margin-left: 10px;
      }
      .blue {
        display: block;
        width: 10px;
        height: 10px;
        background: #2f54eb;
        border-radius: 10px;
      }
      .yellow {
        display: block;
        width: 10px;
        height: 10px;
        background: #ff6300;
        border-radius: 10px;
      }
    }
  }
}

.footer {
  font-family: STHeiti;
  width: 100%;
  // height: 400px;
  line-height: 80px;
  color: #ccc;
  background: #12355b;
  .top {
    display: flex;
    justify-content: flex-start;
    padding-top: 50px;
    .left {
      width: 20%;
      text-align: left;
      margin-left: 10%;
      .list {
        cursor: pointer;
        line-height: 50px;
        a {
          color: #ccc;
        }
      }
    }
    .center {
      width: 30%;
      margin-left: 5%;
      text-align: left;
      .list {
        line-height: 50px;
      }
    }
    .right {
      width: 20%;
      margin-left: 10%;
      text-align: left;
      img {
        width: 55%;
        margin-top: 20px;
      }
    }
  }
  .bottom {
    width: 700px;
    display: flex;
    justify-content: flex-start;
    margin: 10px auto 0 auto;
    font-size: 14px;
  }
  .copyright {
    display: block;
    height: 20px;
    line-height: 20px;
    margin: 0px 0px 0px 5px;
    color: #939393;
    padding: 20px 0;
  }
}
</style>
