<template>
  <div class="test-niche-container">
    <div class="title" v-if="!isBuy">佛龛功能模拟器</div>
    <div class="niche-box" v-if="!isBuy">
      <div class="niche-hint">
        <div class="apply-box"><div class="hint-icon"></div><span>可点击</span></div>
        <div class="applied-box"><div class="hint-icon applied"></div><span>已点击</span></div>
      </div>

      <div class="niche-list">
        <div class="niche" v-for="(value,key) in niche" :key="key" @click="goChoose(key)">
          <div v-if="value.select === ''">{{value.name}}</div>
          <div class="selected" v-else>{{value.name}}</div>
        </div>
      </div>

<!--      <div class="niche-list">-->
<!--        <div class="niche"><div class="button search" @click="showSearch=true">查找</div></div>-->
<!--        <div class="niche"><div class="button bless" @click="showBless=true">祈福</div></div>-->
<!--        <div class="niche"><div class="button confirm" @click="showBuy=true">购买</div></div>-->
<!--      </div>-->
      <div class="niche-list">
        <div class="button confirm" @click="showBuy=true">购买</div>
      </div>

    </div>

    <div class="niche-box" v-else>
      <img class="niche-bg" src="https://cdn.cxc-group.com/tmp/fk.jpg" />
      <div class="niche-mask">
        <div class="title">{{form.name}}的佛龛</div>
      </div>

      <van-notice-bar class="bless-text" color="#FFD248" background="#000000" scrollable>
        {{oledText}}
      </van-notice-bar>

      <div class="bless-button" @click="showBless=true">祈福联动</div>

      <div class="search-button" @click="goSelect">寻找我的佛龛</div>
    </div>

    <van-popup v-model="showBless" round position="bottom" :style="{ height: '30%' }" >
      <div class="niche-info">
        <div class="input-box">
          <div class="input-tag">祈福</div>
          <div class="input-comp"><textarea v-model="form.text" value="" placeholder="请输入祈福语句" /></div>
        </div>
      </div>
      <div class="button-box">
        <div class="button confirm" @click="goBless">选择</div>
      </div>
    </van-popup>
    <van-popup v-model="showBuy" round position="bottom" :style="{ height: '30%' }" >
      <div class="niche-info">
        <div class="input-box">
          <div class="input-tag">姓名</div>
          <div class="input-comp"><input v-model="form.name" value="" placeholder="请输入姓名" /></div>
        </div>
      </div>
      <div class="button-box">
        <div class="button confirm" @click="goConfirm">选择</div>
      </div>
    </van-popup>
  </div>
</template>

<script>
  import { Notify } from 'vant';
  import { Dialog } from 'vant';
  var that;
  export default {
    name: "TestNiche",
    data() {
      return {
        niche:[
          {id:1, x:1,y:1, name:'', text: '',select:''},
          {id:2, x:1,y:2, name:'', text: '',select:''},
          {id:3, x:1,y:3, name:'', text: '',select:''},
          {id:4, x:1,y:4, name:'', text: '',select:''},
          {id:5, x:1,y:5, name:'', text: '',select:''},
          {id:6, x:1,y:6, name:'', text: '',select:''},
          {id:7, x:1,y:7, name:'', text: '',select:''},
          {id:8, x:1,y:8, name:'', text: '',select:''},
          {id:9, x:1,y:9, name:'', text: '',select:''},
        ],

        showBless: false,
        showSearch:false,
        showBuy:false,
        isBuy:false,
        buyKey:'',
        form:{
          id: 0,
          name: '',
          text: '',
        },
        oledText:'佛龛模拟器提醒您，点击祈福按钮检测功能',
        mainDevice:['00','00','00','0a', '00','00'],

        ws:{},
        path:"wss://ws.cxc-group.com",
      }
    },
    methods:{
      goChoose(key){
        for (let i=0;i<9;i++){
          if(key !== i){
            this.niche[i].select = ''
          }
        }

        this.form.id = this.niche[key].select ? 0 : this.niche[key].id;
        this.niche[key].select = this.niche[key].select ? '' : 1;
      },
      goBless(){

        if(this.form.text === ''){
          alert("请输入祈福语句");
          return false;
        }
        if(this.form.text.length < 8){
          alert("祈福语句不能少于8个字");
          return false;
        }

        if(this.form.text.length > 50){
          alert("祈福语句不能大于50个字");
          return false;
        }

        this.niche[this.buyKey].text = this.form.text
        that.ws.send(JSON.stringify({'event':'blessNiche', 'mainDevice':that.mainDevice, 'data':that.niche[that.buyKey]}));
        Notify({ type: 'success', message: '祈福语句发送成功' });
        that.oledText = this.form.text
        this.showBless = false
      },
      goSelect(){
        that.ws.send(JSON.stringify({'event':'findNiche', 'mainDevice':that.mainDevice, 'data':that.niche[that.buyKey]}));
        Dialog.alert({
          message: '是否已经查找佛龛？',
          theme: 'round-button',
        }).then(() => {
          // on close
          console.log(2)
          that.ws.send(JSON.stringify({'event':'foundNiche', 'mainDevice':that.mainDevice, 'data':that.niche[that.buyKey]}));
        });
      },
      goConfirm(){
        console.log(1)
        if(this.form.id === 0){
          alert("请选择位置");
          return false;
        }
        if(this.form.name === ''){
          alert("请输入姓名");
          return false;
        }
        let niche = this.niche;
        Object.keys(niche).map((key) => [niche[key], key]).forEach(([value,key]) => {
          //循环操作
          if(value.id === that.form.id){
            that.buyKey = key
            that.niche[key].name = that.form.name
            that.niche[key].text = that.form.text
            that.niche[key].select = ''
            that.ws.send(JSON.stringify({'event':'nicheChangeOLED', 'mainDevice':that.mainDevice, 'data':that.niche[key]}));
          }
        });
        this.isBuy = true
        this.showBuy = false
        Notify({ type: 'success', message: '模拟购买成功' });
      },
      init() {
        let ping
        // 实例化socket，这里的实例化直接赋值给this.ws是为了后面可以在其它的函数中也能调用websocket方法，例如：this.ws.close(); 完成通信后关闭WebSocket连接
        this.ws = new WebSocket(this.path)

        //监听是否连接成功
        this.ws.onopen = ()=> {
          console.log('ws连接状态：' +this.ws.readyState);
          //连接成功则发送一个数据
          ping = setInterval(function (){
            that.ws.send(JSON.stringify({'event':'ping'}));
          }, 5000)
        }

        //接听服务器发回的信息并处理展示
        this.ws.onmessage = (data)=> {
          // data = JSON.parse(data.data)
          // if(data.data == 'pong') return ;
          // if(data.code === 200) {
          //   return
          // }
          console.log(data.data)
          if(data.data.indexOf("{")>-1){
            return
          }
        }

        //监听连接关闭事件
        this.ws.onclose = ()=>{
          //监听整个过程中websocket的状态
          console.log('ws连接状态：' + this.ws.readyState);
          clearInterval(ping)
          this.ws = new WebSocket(this.path)
        }

        //监听并处理error事件
        this.ws.onerror = function(error) {
          console.log(error);
        }
      }
    },
    mounted() {
      that = this;
      this.init()
    }
  }
</script>
<style scoped lang="scss">
  .test-niche-container{
    box-sizing: border-box;
    width: 100vw;
    height: 100vh;
    overflow: hidden;
    padding: 5%;
    background: linear-gradient(180deg, #241C0B 0%, #756B61 100%);
    .title{
      font-size: 7vw;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #FFFFFF;
      letter-spacing: 2px;
      text-align: left;
      margin-top: 12vw;
    }
    .niche-box{
      width: 100%;
      margin-top: 4vw;
      position: relative;
      .niche-hint{
        display:inline-flex;
        width: 60%;
        .apply-box{
          width: 50%;
          display:inline-flex;
          justify-content: center;
          align-items: center;
          .hint-icon{
            width: 18px;
            height: 18px;
            background: #6E6E6E;
            border-radius: 2px;
            border: 1px solid #FFFFFF;
          }
          span{
            font-size: 13px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #FFFFFF;
            line-height: 20px;
            letter-spacing: 1px;
            margin-left:1vw;
          }

        }
        .applied-box{
          width: 50%;
          display:inline-flex;
          justify-content: center;
          align-items: center;
          .hint-icon{
            width: 20px;
            height: 20px;
            background: #6E6E6E;
            border-radius: 2px;
          }
          .applied{
            background: #BB844B !important;
          }
          span{
            font-size: 13px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #FFFFFF;
            line-height: 20px;
            letter-spacing: 1px;
            margin-left:1vw;
          }
        }
      }
      .niche-list{
        width: 100%;
        margin-top: 4vw;
        display:flex;
        flex-wrap: wrap;
        justify-content: space-between;

        .search{
          width: 100%;
          color:white;
          height: 10vw;
          line-height: 10vw;
          background: #a38c6e;
          border-radius: 6vw;
        }
        .confirm{
          width: 100%;
          height: 10vw;
          line-height: 10vw;
          color:white;
          background: #BB844B;
          border-radius: 10vw;
        }
        .bless{
          width: 100%;
          height: 10vw;
          line-height: 10vw;
          color:white;
          background: #7d5a39;
          border-radius: 6vw;
        }

        .niche{
          border-radius: 2px;
          margin-bottom:3vw;
          div{
            width: calc(28vw - 2px);
            height: calc(28vw - 2px);
            background: #6E6E6E;
            border: 1px solid #FFFFFF;
            text-align: center;
            color:white;
            line-height: calc(28vw - 2px);
          }
          .selected{
            width: 28vw;
            height: 28vw;
            background: #BB844B;
            border:none;
            text-align: center;
            color:white;
            line-height: 28vw;
          }


        }
      }
      .niche-bg{
        width: 100%;
        margin-top:-6vw;
      }
      .niche-mask{
        width: 100vw;
        height: 16vw;
        position: absolute;
        top:0;left:0;
        z-index:2;
        background:rgba(0,0,0,0.4);
        .title{
          margin-top:0;
        }
      }

      .bless-text{
        width: 82vw;
        height: 16vw;
        position: absolute;
        bottom:39.5vw;
        z-index:2;
        font-weight: bold;
      }

      .bless-button{
        width: 91.5%;
        height: 7.7vw;
        position: absolute;
        color: white;
        bottom: 26.8vw;
        line-height: 7.7vw;
        left: 3.5vw;
        z-index: 2;
        margin: 0 auto;
        background: #7d5a39;
      }

      .search-button{
        width: 91.5%;
        height: 7.7vw;
        line-height: 7.7vw;
        position: absolute;
        color: white;
        bottom: 11.8vw;
        left: 3.5vw;
        z-index: 2;
        margin: 0 auto;
        background: #a38c6e;
      }
    }
    .niche-info{
      box-sizing: border-box;
      width: 100%;
      padding: 5%;
      background: #FFFFFF;
      border-radius: 7px;
      margin-top: 4vw;
      .input-box{
        width: 100%;
        display: inline-flex;
        justify-content: space-around;
        margin-bottom: 3vw;
        .input-tag{
          width: 20%;
          font-size: 4vw;
          font-family: PingFangSC-Semibold, PingFang SC;
          font-weight: 600;
          color: #000000;
          line-height: 8vw;
          letter-spacing: 1px;
        }
        .input-comp{
          width: 80%;
          input{
            width: 100%;
            border:none;
            font-size: 4vw;
            font-family: PingFangSC-Semibold, PingFang SC;
            color: #000000;
            line-height: 8vw;
            letter-spacing: 1px;
            border-bottom: 1px solid rgba(0,0,0,0.25);
          }
          textarea{
            width: 100%;
            border:none;
            font-size: 4vw;
            font-family: PingFangSC-Semibold, PingFang SC;
            color: #000000;
            line-height: 8vw;
            letter-spacing: 1px;
            border-bottom: 1px solid rgba(0,0,0,0.25);
          }
        }
      }

      .input-box:last-of-type{
        margin-bottom: 0;
      }
    }

    .button-box{
      width: 100%;
      display:inline-flex;
      justify-content: space-around;
      margin-top:4vw;
      .button{
        width: 50%;
        height: 12vw;
        color:white;
        line-height: 12vw;

      }

      .search{
        background: #FFD5A9;
        border-radius: 6vw;
      }
      .confirm{
        background: #BB844B;
        border-radius: 6vw;
      }
    }
  }
</style>
