<template>
  <div class="apply-box">
    <div class="title">小缘助手使用资质申请</div>
    <div class="form">
      <div class="name">
        姓名
        <input class="input" type="text" v-model="name" />
      </div>
      <div class="name">
        法号
        <input class="input" type="text" v-model="buddhistName" />
      </div>
      <div class="name">
        性别
        <select class="select" v-model="sex">
          <option value="-1" selected>请选择</option>
          <option value="0">男</option>
          <option value="1">女</option>
        </select>
      </div>
      <div class="name">
        身份
        <select class="select" v-model="role">
          <option value="-1" selected>请选择</option>
          <option value="0">方丈</option>
          <option value="1">小师傅</option>
          <option value="2">居士</option>
        </select>
      </div>
      <div class="name">
        寺庙
        <input class="input" type="text" v-model="temple" />
      </div>
      <div class="name">
        寺庙地址
        <div class="choose" @click="addressClick">{{ address }}</div>
        <div class="popup" v-show="show">
          <div class="province">
            <div
              class="list"
              v-for="(v, k) in sheng"
              :key="k"
              @click="provinceClick(v)"
            >
              {{ v.province }}
            </div>
          </div>
          <div class="province">
            <div
              class="list"
              v-for="(v, k) in shi"
              :key="k"
              @click="cityClick(v)"
            >
              {{ v.city }}
            </div>
          </div>
          <div class="province">
            <div
              class="list"
              v-for="(v, k) in qu"
              :key="k"
              @click="chooseArea(v)"
            >
              {{ v.area }}
            </div>
          </div>
        </div>
      </div>
      <div class="name">
        电话
        <input class="input" type="text" v-model="phone" />
      </div>
    </div>
    <div class="submit" @click="submit">提交</div>
  </div>
</template>
<script>
import axios from "axios";
export default {
  name: "MobileApply",
  data() {
    return {
      role: -1,
      sex: -1,
      name: "",
      temple: "",
      buddhistName: "",
      phone: "",
      show: false,
      province: "",
      city: "",
      area: "",
      sheng: [],
      shi: [],
      qu: [],
      address: "请选择地址",
    };
  },
  methods: {
    submit() {
      if (this.name == "") {
        this.$message({
          message: "请输入姓名",
          type: "warning",
        });
      } else if (this.buddhistName == "") {
        this.$message({
          message: "请输入法号",
          type: "warning",
        });
      } else if (this.sex < 0) {
        this.$message({
          message: "请选择性别",
          type: "warning",
        });
      } else if (this.role < 0) {
        this.$message({
          message: "请选择身份",
          type: "warning",
        });
      } else if (this.temple == "") {
        this.$message({
          message: "请输入寺庙名称",
          type: "warning",
        });
      } else if (this.address == "请选择") {
        this.$message({
          message: "请选择地址",
          type: "warning",
        });
      } else if (this.phone == "") {
        this.$message({
          message: "请输入电话号码",
          type: "warning",
        });
      } else if (this.phone != "") {
        let myreg = /^[1][3,4,5,7,8][0-9]{9}$/;
        if (!myreg.test(this.phone)) {
          this.$message.warning("请输入正确的手机号");
        }
      }
      if (
        this.name != "" &&
        this.buddhistName != "" &&
        this.sex != "" &&
        this.role != "" &&
        this.temple != "" &&
        this.phone != "" &&
        this.address != ""
      ) {
        let url = "/assistant/apply";
        const params = {
          name: this.name,
          buddhistName: this.buddhistName,
          sex: this.sex,
          role: this.role,
          temple: this.temple,
          phone: this.phone,
          address: this.address,
          province_id: this.provinceid,
          city_id: this.cityid,
          area_id: this.areaid,
        };
        axios({
          url: url,
          method: "POST",
          data: params,
          heads: {
            "content-type": "application/x-www-form-urlencoded",
          },
        }).then((res) => {
          console.log(res);
          if (res.data.code == 200) {
            this.$router.push("/m/submit");
          }
        });
      }
    },
    addressClick() {
      this.show = true;
    },
    provinceClick(v) {
      console.log(v.provinceid);
      this.province = v.province;
      this.provinceid = v.provinceid;
      axios({
        url: "/province/" + v.provinceid,
        method: "GET",
        heads: {
          "content-type": "application/x-www-form-urlencoded",
        },
      }).then((res) => {
        this.shi = res.data.data;
        this.qu = [];
        this.address = "";
        console.log(this.shi);
      });
    },
    cityClick(v) {
      console.log(v.cityid);
      this.city = v.city;
      this.cityid = v.cityid;
      axios({
        url: "/city/" + v.cityid,
        method: "GET",
        heads: {
          "content-type": "application/x-www-form-urlencoded",
        },
      }).then((res) => {
        this.qu = res.data.data;
        this.address = "";
        console.log(this.qu);
      });
    },
    chooseArea(v) {
      console.log(v.areaid);
      this.area = v.area;
      this.areaid = v.areaid;
      this.address = this.province + this.city + this.area;
      this.show = false;
      console.log(this.address);
    },
  },
  mounted() {
    axios({
      url: "/province",
      method: "GET",
      heads: {
        "content-type": "application/x-www-form-urlencoded",
      },
    }).then((res) => {
      this.sheng = res.data.data;
      console.log(this.sheng);
    });
  },
};
</script>

<style lang="scss" scoped>
.apply-box {
  width: 100%;
  height: 100vh;
  background: #f1f2f3;
  .title {
    padding-top: 46px;
    display: flex;
    justify-content: center;
    font-size: 24px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #000000;
    line-height: 33px;
    letter-spacing: 2px;
  }
  .form {
    width: 94%;
    margin-left: 3%;
    margin-top: 16px;
    background: #fff;
    border-radius: 10px;
    .name {
      width: 90%;
      margin-left: 5%;
      height: 54px;
      line-height: 54px;
      display: flex;
      font-size: 14px;
      color: #000000;
      justify-content: space-between;
      border-bottom: 1px solid #eeeeee;
      .input {
        border: none;
        width: 80%;
        text-align: right;
        color: rgba(0, 0, 0, 0.65);
        letter-spacing: 1px;
      }
      .select {
        border: none;
        background: #fff;
        height: 40px;
        line-height: 40px;
        margin-top: 10px;
        outline: none;
        width: 18%;
        // direction: rtl;
      }
      .choose {
        width: 40%;
        text-align: right;
        padding-right: 10px;
      }
      .popup {
        position: fixed;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 300px;
        background: #fff;
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
        display: flex;
        justify-content: start;
        box-shadow: darkgrey 10px 10px 30px 5px;
        .province {
          width: 28%;
          margin-top: 10px;
          margin-left: 5%;
          overflow: scroll;
        }
        .province::-webkit-scrollbar {
          display: none;
        }
      }
    }
  }
  .submit {
    width: 94%;
    margin-left: 3%;
    margin-top: 180px;
    height: 60px;
    line-height: 60px;
    background: #d9bc94;
    border-radius: 10px;
    color: #fff;
    font-size: 18px;
    letter-spacing: 1px;
  }
}
</style>
