<template>
  <div class="test-niche-container">
    <div class="title">我的祈福</div>
    <div class="box">
      <van-image class="img" src="https://cdn.cxc-group.com/website/lhd-clear.png" fit="contain" />
      <div class="name">莲花灯</div>
      <div class="area">观音殿</div>
      <van-field
        readonly
        clickable
        :value="value"
        placeholder="请选择祈福时间"
        @click="showPicker = true"
      />
      <van-popup v-model="showPicker" round position="bottom">
        <van-picker
          show-toolbar
          :columns="columns"
          @cancel="showPicker = false"
          @confirm="onConfirm"
        />
      </van-popup>
      <textarea
        v-model="content"
        class="input"
        placeholder="请输入你的祈福语句"
        maxlength="80"
        minlength="8"
      ></textarea>
    </div>
    <div class="submit" @click="submit">祈福</div>
  </div>
</template>

<script>
var that;
export default {
  name: "TestNiche",
  data() {
    return {
      ws: {},
      path: "wss://ws.cxc-group.com",
      content: "",
      time: "",
      value: "",
      showPicker: false,
      columns: [
        {'text': '10秒', 'second': '10'},
        {'text': '30秒', 'second': '30'},
        {'text': '1分钟', 'second': '60'},
        {'text': '3分钟', 'second': '180'},
        {'text': '10分钟', 'second': '600'},
      ],
      second:0,
    };
  },
  methods: {
    onConfirm(value) {
      console.log(value)
      this.value = value.text;
      this.second = value.second
      this.showPicker = false;
    },
    submit() {
      if(this.second === 0) return alert("请选择祈福时间");
      if(this.text === '') return alert("请输入祈福语句")
      localStorage.setItem('lamp_bless_text', that.content);

      that.ws.send(JSON.stringify({'event':'slamp_bless', 'id':1, 'data':{
        'text':that.content, 'second':that.second
      }}));

      this.$router.push("/m/myQf");
    },
    init() {
      // 实例化socket，这里的实例化直接赋值给this.ws是为了后面可以在其它的函数中也能调用websocket方法，例如：this.ws.close(); 完成通信后关闭WebSocket连接
      this.ws = new WebSocket(this.path);

      //监听是否连接成功
      this.ws.onopen = () => {
        console.log("ws连接状态：" + this.ws.readyState);
        //连接成功则发送一个数据
        setInterval(function() {
          that.ws.send(JSON.stringify({ event: "ping" }));
        }, 5000);
      };

      //接听服务器发回的信息并处理展示
      this.ws.onmessage = data => {
        // console.log('接收到来自服务器的消息：');
        console.log(data);
      };

      //监听连接关闭事件
      this.ws.onclose = () => {
        //监听整个过程中websocket的状态
        console.log("ws连接状态：" + this.ws.readyState);
      };

      //监听并处理error事件
      this.ws.onerror = function(error) {
        console.log(error);
      };
    }
  },
  mounted() {
    that = this;
    this.init();
  }
};
</script>
<style scoped lang="scss">
.test-niche-container {
  box-sizing: border-box;
  width: 100vw;
  min-height: 100vh;
  padding: 5%;
  background: linear-gradient(180deg, #241c0b 0%, #756b61 100%);
  .title {
    font-size: 7vw;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #ffffff;
    letter-spacing: 2px;
    text-align: left;
    margin-top: 6vw;
  }
  .box {
    width: 100%;
    // height: 80%;
    background: #ffffff;
    border-radius: 10px;
    margin-top: 66px;
    position: relative;
    .img {
      position: absolute;
      top: -9%;
      left: 30%;
      width: 40%;
      height: 179px;
    }
    .name {
      font-size: 24px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #000000;
      line-height: 33px;
      letter-spacing: 2px;
      text-align: center;
      padding-top: 40%;
    }
    .area {
      font-size: 18px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 400;
      color: #000000;
      line-height: 33px;
      letter-spacing: 2px;
      text-align: center;
    }
    .van-field {
      width: 90%;
      margin-left: 5%;
      border: 2px solid #f3f3f3;
      border-radius: 5px;
      margin-top: 20px;
    }
    .input {
      width: 80%;
      /*height: 164px;*/
      background: #f3f3f3;
      border-radius: 10px;
      border: none;
      padding: 16px 20px;
      margin-top: 20px;
      margin-bottom: 20px;
    }
  }
  .submit {
    width: 100%;
    height: 50px;
    line-height: 50px;
    background: #bb844b;
    border-radius: 50px;
    color: #fff;
    margin-top: 40px;
  }
}
</style>
