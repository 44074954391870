<template>
  <div class="header">
    <div class="logo">
      <img src="https://cdn.cxc-group.com/website/web-logo.png" />
    </div>
    <div class="content">
      <div @click="toIndex()">
        首页
        <span></span>
      </div>
      <div @click="toIndex()">
        小缘系统
        <span></span>
      </div>
      <!-- <div @click="toIndex()">
        产品中心
        <span></span>
      </div> -->
      <div @click="toIndex()">
        服务支持
        <span></span>
      </div>
      <div @click="about()">
        关于小缘
        <span></span>
      </div>
    </div>
  </div>
</template>

<script>
// import HelloWorld from '@/components/HelloWorld.vue'

export default {
  name: "header",
  components: {},
  methods: {
    toIndex() {
      this.$router.push({ name: "Home" });
    },
    about() {
      this.$router.push({ name: "About" });
    }
  }
};
</script>
<style lang="scss" scoped="scoped">
.header {
  font-family: STHeiti;
  display: flex;
  justify-content: space-between;
  background: #12355b;
  position: fixed;
  height: 80px;
  left: 0;
  top: 0;
  width: 100%;
  z-index: 40000;
  .logo {
    width: 20%;
    margin-left: 4%;
    img {
      width: 150px;
      margin-top: 10px;
    }
  }
  .content {
    width: 54%;
    // margin-left: 20%;
    display: flex;
    justify-content: space-around;
    margin-right: 8%;
    font-size: 18px;
    div {
      width: 20%;
      height: 80px;
      line-height: 80px;
      color: #fff;
      cursor: pointer;
      a {
        display: block;
        width: 100%;
        height: 80px;
        line-height: 80px;
        text-decoration: none;
        color: #fff;
      }
    }
    div:hover {
      color: #fff;
      background: #2f54eb;
      a {
        color: #fff;
        background: #2f54eb;
      }
    }
  }
}
</style>
