<template>
  <div class="apply-box">
    <div class="title">
      <img
        class="img"
        src="https://cdn.cxc-group.com/website/success.png"
        alt="logo"
      />
      <!-- <van-image
        src="https://cdn.cxc-group.com/website/success.png"
        fit="cover"
      /> -->
      <div>提交成功</div>
    </div>
    <div class="form">
      <div class="top">
        1-3个工作日内，工作人员将通过电话确认，
        <br />请务必接听！ <br />审核结果由公众号通知
      </div>
      <div class="top">
        下载小缘助手APP
        <br />抢先体验！
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "MobileApply",
  data() {
    return {};
  },
  methods: {
    goHome(index) {
      localStorage.setItem("mobilePage", index);
      this.$router.push("/m/");
    },
  },
  mounted() {},
};
</script>

<style scoped lang="scss">
.apply-box {
  width: 100%;
  height: 100vh;
  background: #f1f2f3;
  padding-top: 46px;
  .title {
    font-size: 18px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #000000;
    width: 380px;
    margin-left: 4%;
    height: 173px;
    background: #ffffff;
    border-radius: 4px;
    letter-spacing: 1px;
    .img {
      width: 62px;
      height: 62px;
      padding-top: 37px;
    }
    div {
      width: 100%;
      height: 50px;
      display: flex;
      justify-content: center;
      padding-top: 12px;
    }
  }
  .form {
    font-size: 18px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #000000;
    width: 380px;
    margin-left: 4%;
    margin-top: 16px;
    // height: 173px;
    background: #ffffff;
    border-radius: 4px;
    letter-spacing: 1px;
    .top {
      width: 350px;
      margin-left: 16px;
      padding-top: 18px;
      height: 64px;
      font-size: 14px;
      font-weight: 400;
      color: #000000;
      line-height: 26px;
      letter-spacing: 1px;
      text-align: left;
      border-bottom: 1px solid #eeeeee;
    }
  }
}
</style>
