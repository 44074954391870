<template>
  <div class="home-box bar">
    <van-nav-bar title="小缘文化 CXC-GROUP.COM" left-arrow @click-left="show=true">
      <template #left>
        <van-icon name="wap-nav" size="18" />
      </template>
    </van-nav-bar>
    <!-- <div class="top-box">
      <div class="swiper-box">
        <van-swipe :autoplay="3000">
          <van-swipe-item class="banner-first">
            <van-image
              class="banner-first-bg"
              src="https://cdn.cxc-group.com/website/new/banner1-bg.jpg"
              fit="cover"
            />
            <van-image
              class="banner-first-title"
              src="https://cdn.cxc-group.com/website/new/banner1-title.png"
              fit="contain"
            />
            <van-image
              class="banner-first-hint"
              src="https://cdn.cxc-group.com/website/new/3.png"
              fit="contain"
            />
            <van-image
              class="banner-first-text"
              src="https://cdn.cxc-group.com/website/new/banner1-text.png"
              fit="contain"
            />
          </van-swipe-item>
          <van-swipe-item>
            <img class="banner-second" :src="banner[1]" />
          </van-swipe-item>
        </van-swipe>
      </div>
    </div> -->
    <div class="content-box margin">
      <div class="content-title">
        <van-image src="https://cdn.cxc-group.com/website/new/4.png" fit="contain" />
        <div class="content-title-text">
          <van-image src="https://cdn.cxc-group.com/website/new/12.png" fit="contain" />
          <span>小缘始终致力于智慧化系统与产品研发</span>
          <van-image src="https://cdn.cxc-group.com/website/new/13.png" fit="contain" />
        </div>

        <div class="do-what-box">
          <div class="do-what-list">
            <div class="do-what-list-title">
              <van-image src="https://cdn.cxc-group.com/website/new/5.png" fit="cover" />
            </div>
            <div class="do-what-list-image">
              <van-image src="https://cdn.cxc-group.com/website/new/28.png" fit="cover" />
            </div>
            <div class="do-what-list-text-box">
              <h2>小缘智慧平台</h2>
              <van-image src="https://cdn.cxc-group.com/website/new/10.png" fit="contain"></van-image>
              <p>
                接入多个系统，大数据，
                <br />核心算法，管理更便捷
              </p>
            </div>
          </div>
          <!-- <div class="do-what-list">
            <div class="do-what-list-title">
              <van-image src="https://cdn.cxc-group.com/website/new/6.png" fit="cover" />
            </div>
            <div class="do-what-list-image">
              <van-image src="https://cdn.cxc-group.com/website/new/29.png" fit="cover" />
            </div>
            <div class="do-what-list-text-box">
              <h2>殿堂方案设计</h2>
              <van-image src="https://cdn.cxc-group.com/website/new/10.png" fit="contain"></van-image>
              <p>
                面向不同寺院殿堂的需求，提供深度的
                <br />智慧殿堂方案，满足核心诉求
              </p>
            </div>
          </div> -->
          <div class="do-what-list">
            <div class="do-what-list-title">
              <van-image src="https://cdn.cxc-group.com/website/new/6.png" fit="cover" />
            </div>
            <div class="do-what-list-image">
              <van-image src="https://cdn.cxc-group.com/website/new/30.png" fit="cover" />
            </div>
            <div class="do-what-list-text-box">
              <h2>智能硬件开发</h2>
              <van-image src="https://cdn.cxc-group.com/website/new/10.png" fit="contain"></van-image>
              <p>
                定制化开发适合所需求的产品，
                <br />实现数据科技采集便捷
              </p>
            </div>
          </div>
          <!-- <div class="do-what-list">
            <div class="do-what-list-title">
              <van-image src="https://cdn.cxc-group.com/website/new/8.png" fit="cover" />
            </div>
            <div class="do-what-list-image">
              <van-image src="https://cdn.cxc-group.com/website/new/31.png" fit="cover" />
            </div>
            <div class="do-what-list-text-box">
              <h2>项目落地实施</h2>
              <van-image src="https://cdn.cxc-group.com/website/new/10.png" fit="contain"></van-image>
              <p>
                完善的项目实施与售后体系，
                <br />推动智慧寺院全国落地
              </p>
            </div>
          </div> -->
        </div>
      </div>
    </div>
    <!--  科技改变世界  -->
    <div class="content-box orange-bg">
      <div class="content-title">
        <van-image src="https://cdn.cxc-group.com/website/new/11.png" fit="contain" />
        <div class="content-title-text margin">
          <van-image src="https://cdn.cxc-group.com/website/new/12.png" fit="contain" />
          <span style="line-height: 4vw">
            小缘以O2O的模式，
            <br />为更多的企业带来可持续的创新价值
          </span>
          <van-image src="https://cdn.cxc-group.com/website/new/13.png" fit="contain" />
        </div>

        <div class="change-world-box">
          <div class="change-world-list">
            <van-image src="https://cdn.cxc-group.com/website/new/32.png" fit="cover"></van-image>
            <h2>采集</h2>
            <p>
              多智能终端，
              <br />多维度精准收集
            </p>
          </div>
          <div class="change-world-list">
            <van-image src="https://cdn.cxc-group.com/website/new/33.png" fit="cover"></van-image>
            <h2>分析</h2>
            <p>
              后台人工智能
              <br />全天24小时解析处理
            </p>
          </div>
          <div class="change-world-list">
            <van-image src="https://cdn.cxc-group.com/website/new/34.png" fit="cover"></van-image>
            <h2>下发</h2>
            <p>
              精准、智能、高效、
              <br />针对性投射
            </p>
          </div>
        </div>
      </div>
    </div>

    <!--  后疫情  -->
    <div class="content-box">
      <van-image
        class="background-image"
        src="https://cdn.cxc-group.com/website/new/15.jpg"
        fit="cover"
      />
      <!-- <div class="content-title" style="margin-top: 8vw;">
        <van-image src="https://cdn.cxc-group.com/website/new/14.png" fit="contain" />
        <div class="epidemic-situation-box">
          <div class="epidemic-situation-list">
            <div class="epidemic-situation-list-image">
              <van-image src="https://cdn.cxc-group.com/website/new/35.png" fit="contain"></van-image>
            </div>
            <h2>线上祈福</h2>
            <p>线下同步</p>
          </div>
          <div class="epidemic-situation-list">
            <div class="epidemic-situation-list-image">
              <van-image src="https://cdn.cxc-group.com/website/new/36.png" fit="contain"></van-image>
            </div>
            <h2>线上预约</h2>
            <p>法会不缺席，佛节不缺席</p>
          </div>
          <div class="epidemic-situation-list">
            <div class="epidemic-situation-list-image">
              <van-image src="https://cdn.cxc-group.com/website/new/37.png" fit="contain"></van-image>
            </div>
            <h2>线上祭奠</h2>
            <p>选择供奉，线下同步供奉实物</p>
          </div>
        </div>
      </div>
    </div> -->

    <!--  小缘系统  -->
    <!-- <div class="content-box bar1 bar">
      <div class="content-title margin">
        <van-image src="https://cdn.cxc-group.com/website/new/16.png" fit="contain" />
        <div class="content-title-text">
          <van-image src="https://cdn.cxc-group.com/website/new/12.png" fit="contain" />
          <span>基于阿里云强大的基础资源</span>
          <van-image src="https://cdn.cxc-group.com/website/new/13.png" fit="contain" />
        </div>

        <div class="cxc-system-box">
          <div class="cxc-system-list">
            <div class="cxc-system-list-title">
              <van-image src="https://cdn.cxc-group.com/website/new/title.png" fit="cover" />
              <div class="cxc-system-list-title-text">接设备</div>
            </div>
            <div class="cxc-system-list-image">
              <van-image src="https://cdn.cxc-group.com/website/new/jsb.png" fit="cover" />
            </div>
            <div class="cxc-system-list-text-box">
              <div>
                <van-image src="https://cdn.cxc-group.com/website/new/10.png" fit="contain"></van-image>
                <p>
                  佛龛，莲花灯，蜡烛，牌位，
                  <br />荷花台等多入口
                </p>
                <van-image src="https://cdn.cxc-group.com/website/new/10.png" fit="contain"></van-image>
              </div>
            </div>
          </div>
          <div class="cxc-system-list">
            <div class="cxc-system-list-title">
              <van-image src="https://cdn.cxc-group.com/website/new/title.png" fit="cover" />
              <div class="cxc-system-list-title-text">管设备</div>
            </div>
            <div class="cxc-system-list-image">
              <van-image src="https://cdn.cxc-group.com/website/new/gsb.png" fit="cover" />
            </div>
            <div class="cxc-system-list-text-box">
              <div>
                <van-image src="https://cdn.cxc-group.com/website/new/10.png" fit="contain"></van-image>
                <p>PC端，IPAD,手机等多终端管理</p>
                <van-image src="https://cdn.cxc-group.com/website/new/10.png" fit="contain"></van-image>
              </div>
            </div>
          </div>
          <div class="cxc-system-list">
            <div class="cxc-system-list-title">
              <van-image src="https://cdn.cxc-group.com/website/new/title.png" fit="cover" />
              <div class="cxc-system-list-title-text">采数据</div>
            </div>
            <div class="cxc-system-list-image">
              <van-image src="https://cdn.cxc-group.com/website/new/csh.png" fit="cover" />
            </div>
            <div class="cxc-system-list-text-box">
              <div>
                <van-image src="https://cdn.cxc-group.com/website/new/10.png" fit="contain"></van-image>
                <p>
                  后台服务器云计算，
                  <br />物联网，精准采集
                </p>
                <van-image src="https://cdn.cxc-group.com/website/new/10.png" fit="contain"></van-image>
              </div>
            </div>
          </div>
          <div class="cxc-system-list">
            <div class="cxc-system-list-title">
              <van-image src="https://cdn.cxc-group.com/website/new/title.png" fit="cover" />
              <div class="cxc-system-list-title-text">供服务</div>
            </div>
            <div class="cxc-system-list-image">
              <van-image src="https://cdn.cxc-group.com/website/new/gfw.png" fit="cover" />
            </div>
            <div class="cxc-system-list-text-box">
              <div>
                <van-image src="https://cdn.cxc-group.com/website/new/10.png" fit="contain"></van-image>
                <p>
                  线上线下一站式O2O服务，
                  <br />不断提高效率
                </p>
                <van-image src="https://cdn.cxc-group.com/website/new/10.png" fit="contain"></van-image>
              </div>
            </div>
          </div>
          <div class="cxc-system-list">
            <div class="cxc-system-list-title">
              <van-image src="https://cdn.cxc-group.com/website/new/title.png" fit="cover" />
              <div class="cxc-system-list-title-text">云控台</div>
            </div>
            <div class="cxc-system-list-image">
              <van-image src="https://cdn.cxc-group.com/website/new/ykt.png" fit="cover" />
            </div>
            <div class="cxc-system-list-text-box">
              <div>
                <van-image src="https://cdn.cxc-group.com/website/new/10.png" fit="contain"></van-image>
                <p>
                  采用阿里高端服务器，大规模分布式计算机，
                  <br />数万笔/秒级并发能力，严格保证数据统一性
                </p>
                <van-image src="https://cdn.cxc-group.com/website/new/10.png" fit="contain"></van-image>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div> -->

    <!--  平台优势  -->
    <div class="content-box orange-bg">
      <div class="content-title">
        <van-image src="https://cdn.cxc-group.com/website/new/17.png" fit="contain" />
        <div class="content-title-text margin">
          <van-image src="https://cdn.cxc-group.com/website/new/12.png" fit="contain" />
          <span style="line-height: 4vw">基于阿里云强大的基础资源</span>
          <van-image src="https://cdn.cxc-group.com/website/new/13.png" fit="contain" />
        </div>

        <div class="change-world-box">
          <div class="change-world-list">
            <van-image src="https://cdn.cxc-group.com/website/new/39.png" fit="cover"></van-image>
            <h2>小缘助手</h2>
            <p>
              数字化管理
            </p>
          </div>
          <div class="change-world-list">
            <van-image src="https://cdn.cxc-group.com/website/new/40.png" fit="cover"></van-image>
            <h2>小缘商城</h2>
            <p>
              严选供应链
            </p>
          </div>
          <div class="change-world-list">
            <van-image src="https://cdn.cxc-group.com/website/new/41.png" fit="cover"></van-image>
            <h2>小缘圈子</h2>
            <p>
              社交一体化
            </p>
          </div>
        </div>
      </div>
    </div>

    <!--  产品中心  -->
    <!-- <div class="content-box bar2 bar">
      <div class="content-title margin">
        <van-image src="https://cdn.cxc-group.com/website/new/18.png" fit="contain" />
        <div class="product-box">
          <div class="product-list">
            <div class="product-list-title">
              <van-image src="https://cdn.cxc-group.com/website/new/title.png" fit="cover" />
              <div class="product-list-title-text">佛龛</div>
            </div>
            <div class="product-list-image">
              <van-swipe :autoplay="3000">
                <van-swipe-item>
                  <van-image src="https://cdn.cxc-group.com/website/new/fk1.jpg" fit="cover" />
                </van-swipe-item>
                <van-swipe-item>
                  <van-image src="https://cdn.cxc-group.com/website/new/fk2.jpg" fit="cover" />
                </van-swipe-item>
              </van-swipe>
            </div>
            <div class="product-list-text-box"></div>
            <div class="product-list-text-box-top"></div>
          </div>

          <div class="product-list">
            <div class="product-list-title">
              <van-image src="https://cdn.cxc-group.com/website/new/title.png" fit="cover" />
              <div class="product-list-title-text">莲花灯</div>
            </div>
            <div class="product-list-image">
              <van-swipe :autoplay="3000">
                <van-swipe-item>
                  <van-image src="https://cdn.cxc-group.com/website/new/lhd.jpg" fit="cover" />
                </van-swipe-item>
              </van-swipe>
            </div>
            <div class="product-list-text-box"></div>
            <div class="product-list-text-box-top"></div>
          </div>

          <div class="product-list">
            <div class="product-list-title">
              <van-image src="https://cdn.cxc-group.com/website/new/title.png" fit="cover" />
              <div class="product-list-title-text">烛台</div>
            </div>
            <div class="product-list-image">
              <van-swipe :autoplay="3000">
                <van-swipe-item>
                  <van-image src="https://cdn.cxc-group.com/website/new/zt.jpg" fit="cover" />
                </van-swipe-item>
              </van-swipe>
            </div>
            <div class="product-list-text-box"></div>
            <div class="product-list-text-box-top"></div>
          </div>

          <div class="product-list">
            <div class="product-list-title">
              <van-image src="https://cdn.cxc-group.com/website/new/title.png" fit="cover" />
              <div class="product-list-title-text">蜡烛灯</div>
            </div>
            <div class="product-list-image">
              <van-swipe :autoplay="3000">
                <van-swipe-item>
                  <van-image src="https://cdn.cxc-group.com/website/new/lzd.jpg" fit="cover" />
                </van-swipe-item>
              </van-swipe>
            </div>
            <div class="product-list-text-box"></div>
            <div class="product-list-text-box-top"></div>
          </div>

          <div class="product-list">
            <div class="product-list-title">
              <van-image src="https://cdn.cxc-group.com/website/new/title.png" fit="cover" />
              <div class="product-list-title-text">排位</div>
            </div>
            <div class="product-list-image">
              <van-swipe :autoplay="3000">
                <van-swipe-item>
                  <van-image src="https://cdn.cxc-group.com/website/new/pw.jpg" fit="cover" />
                </van-swipe-item>
              </van-swipe>
            </div>
            <div class="product-list-text-box"></div>
            <div class="product-list-text-box-top"></div>
          </div>

          <div class="product-list">
            <div class="product-list-title">
              <van-image src="https://cdn.cxc-group.com/website/new/title.png" fit="cover" />
              <div class="product-list-title-text">荷花台</div>
            </div>
            <div class="product-list-image">
              <van-swipe :autoplay="3000">
                <van-swipe-item>
                  <van-image src="https://cdn.cxc-group.com/website/new/hht.jpg" fit="cover" />
                </van-swipe-item>
              </van-swipe>
            </div>
            <div class="product-list-text-box"></div>
            <div class="product-list-text-box-top"></div>
          </div>
        </div>
      </div> -->
    </div>

    <!--  服务支持  -->
    <div class="content-box bar3 bar">
      <van-image
        class="background-image"
        src="https://cdn.cxc-group.com/website/new/42.jpg"
        fit="cover"
      />
      <div class="content-title margin" style="margin-top:10vw;padding-bottom: 10vw;">
        <van-image src="https://cdn.cxc-group.com/website/new/43.png" fit="contain" />
        <div class="serve-box">
          <p>
            针对各类应用场景，规模，
            <br />小缘团队提供全方位的服务支持方案，保障项目落地实施与运行维护
          </p>
        </div>
      </div>
    </div>

    <!--  施工流程  -->
    <div class="content-box margin">
      <div class="content-title">
        <van-image src="https://cdn.cxc-group.com/website/new/22.png" fit="contain" />
        <div class="construction-process-box">
          <div class="construction-process-list">
            <div class="construction-process-list-title">
              <van-image src="https://cdn.cxc-group.com/website/new/5.png" fit="cover" />
            </div>
            <div class="construction-process-list-image">
              <van-image src="https://cdn.cxc-group.com/website/new/44.png" fit="cover" />
            </div>
            <div class="construction-process-list-text-box-top"></div>
            <div class="construction-process-list-text-box">
              <div>
                <van-image src="https://cdn.cxc-group.com/website/new/10.png" fit="contain"></van-image>
                <p>商品下单</p>
                <van-image src="https://cdn.cxc-group.com/website/new/10.png" fit="contain"></van-image>
              </div>
            </div>
          </div>
          <div class="construction-process-list">
            <div class="construction-process-list-title">
              <van-image src="https://cdn.cxc-group.com/website/new/6.png" fit="cover" />
            </div>
            <div class="construction-process-list-image">
              <van-image src="https://cdn.cxc-group.com/website/new/45.png" fit="cover" />
            </div>
            <div class="construction-process-list-text-box-top"></div>
            <div class="construction-process-list-text-box">
              <div>
                <van-image src="https://cdn.cxc-group.com/website/new/10.png" fit="contain"></van-image>
                <p>预约施工</p>
                <van-image src="https://cdn.cxc-group.com/website/new/10.png" fit="contain"></van-image>
              </div>
            </div>
          </div>
          <div class="construction-process-list">
            <div class="construction-process-list-title">
              <van-image src="https://cdn.cxc-group.com/website/new/7.png" fit="cover" />
            </div>
            <div class="construction-process-list-image">
              <van-image src="https://cdn.cxc-group.com/website/new/46.png" fit="cover" />
            </div>
            <div class="construction-process-list-text-box-top"></div>
            <div class="construction-process-list-text-box">
              <div>
                <van-image src="https://cdn.cxc-group.com/website/new/10.png" fit="contain"></van-image>
                <p>上门服务</p>
                <van-image src="https://cdn.cxc-group.com/website/new/10.png" fit="contain"></van-image>
              </div>
            </div>
          </div>
          <div class="construction-process-list">
            <div class="construction-process-list-title">
              <van-image src="https://cdn.cxc-group.com/website/new/8.png" fit="cover" />
            </div>
            <div class="construction-process-list-image">
              <van-image src="https://cdn.cxc-group.com/website/new/47.png" fit="cover" />
            </div>
            <div class="construction-process-list-text-box-top"></div>
            <div class="construction-process-list-text-box">
              <div>
                <van-image src="https://cdn.cxc-group.com/website/new/10.png" fit="contain"></van-image>
                <p>验收测试</p>
                <van-image src="https://cdn.cxc-group.com/website/new/10.png" fit="contain"></van-image>
              </div>
            </div>
          </div>
          <div class="construction-process-list">
            <div class="construction-process-list-title">
              <van-image src="https://cdn.cxc-group.com/website/new/9.png" fit="cover" />
            </div>
            <div class="construction-process-list-image">
              <van-image src="https://cdn.cxc-group.com/website/new/48.png" fit="cover" />
            </div>
            <div class="construction-process-list-text-box-top"></div>
            <div class="construction-process-list-text-box">
              <div>
                <van-image src="https://cdn.cxc-group.com/website/new/10.png" fit="contain"></van-image>
                <p>售后维护</p>
                <van-image src="https://cdn.cxc-group.com/website/new/10.png" fit="contain"></van-image>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!--  服务网络  -->
    <div class="content-box dark-bg">
      <div class="content-title margin">
        <van-image src="https://cdn.cxc-group.com/website/new/23.png" fit="contain" />
        <div class="content-title-text margin">
          <van-image src="https://cdn.cxc-group.com/website/new/12.png" fit="contain" />
          <span style="line-height: 4vw; color:white;">携手万家寺院，小缘人在努力</span>
          <van-image src="https://cdn.cxc-group.com/website/new/13.png" fit="contain" />
        </div>
        <div class="map-box">
          <div id="main" class="echart" style="width: 100%;height: 68vw;"></div>
        </div>
      </div>
      <div class="intro">
        <div class="top">
          <span class="blue"></span>
          <div>蓝色圆点为已开通区域</div>
        </div>
        <div class="top">
          <span class="yellow"></span>
          <div>黄色圆点为待开通区域</div>
        </div>
      </div>
    </div>

    <div class="footer-box">
      <div class="footer-list-box">
        <div class="footer-title">关注我们</div>
        <div class="footer-list2" style="margin-top: 4vw;">
          <van-image src="https://cdn.cxc-group.com/website/ewm.jpg" fit="cover" />
        </div>
        <div class="footer-list2">微信扫一扫关注我们</div>
      </div>
      <div class="footer-list-box">
        <div class="footer-title">商务合作</div>
        <div class="footer-list2">如果您有商务合作的需要请联系我们</div>
        <div class="footer-list" style="margin-top: 4vw;">
          <a href="tel:0571-87766335">
            <van-icon name="phone" />0571-87766335
          </a>
        </div>
        <div class="footer-list">
          <van-icon name="invition" />postmaster@cxc-group.com
        </div>
        <div class="footer-list">
          <van-icon name="map-marked" />杭州市西湖区三墩镇留祥路西行桥74号丰瑞大厦B幢401室
        </div>
      </div>

      <div data-v-94f0d06a class="bottom">
        <a
          data-v-94f0d06a
          href="https://beian.miit.gov.cn/"
          class="copyright"
        >Copyright 2020 杭州小缘文化科技有限公司 版权所有</a>
        <a
          data-v-94f0d06a
          target="_blank"
          href="https://beian.miit.gov.cn"
          class="copyright"
          style="display: inline-block; text-decoration: none; height: 20px; line-height: 20px;"
        >
          <img
            data-v-94f0d06a
            src="https://cdn.cxc-group.com/website/footer.png"
            style="float: left;"
          />
          <p
            data-v-94f0d06a
            style="float: left; height: 20px; line-height: 20px; margin: 0px 0px 0px 5px; color: rgb(147, 147, 147);"
          >浙ICP备2020036517号</p>
        </a>
      </div>
    </div>

    <van-popup
      v-model="show"
      position="top"
      closeable
      close-icon-position="top-left"
      :style="{ height: '100%' }"
    >
      <div class="menu-box">
        <div class="menu-title">小缘文化 CXC-GROUP.COM</div>
        <div class="menu">
          <div class="menu-list" @click="scrollPage(0)">首页</div>
          <div class="menu-list" @click="scrollPage(1)">小缘系统</div>
          <div class="menu-list" @click="scrollPage(2)">产品中心</div>
          <div class="menu-list" @click="scrollPage(3)">服务支持</div>
          <div class="menu-list" @click="about">关于小缘</div>
        </div>
      </div>
    </van-popup>
  </div>
</template>

<script>
import "../../../node_modules/echarts/map/js/china.js";
import optionMap from "../../../public/map.js";
import echarts from "echarts";
export default {
  name: "Home.vue",
  data() {
    return {
      show: false,
      isActive: 1,
      active: 0,
      images: ["https://cdn.cxc-group.com/website/banner1-bg.png"],
      banner: [
        "https://cdn.cxc-group.com/website/banner1-bg.png",
        "https://cdn.cxc-group.com/website/new/51.png"
      ]
    };
  },
  mounted() {
    let mobilePage = localStorage.getItem("mobilePage");
    if (mobilePage != undefined && mobilePage != null) {
      this.jump(mobilePage);
      localStorage.removeItem("mobilePage");
    }
    this.$nextTick(function() {
      window.addEventListener("scroll", this.onScroll);
    });
    this.initEchart();
  },
  methods: {
    equip(e) {
      console.log(e);
      this.isActive = e;
    },
    scrollPage(index) {
      this.jump(index);
      this.show = false;
    },
    about() {
      this.$router.push({ path: "/m/about" });
    },
    jump(index) {
      // 用 class="instance_title" 添加锚点
      let jump = document.querySelectorAll(".bar");
      console.log(jump);
      let total = jump[index].offsetTop - 84;
      let distance =
        document.documentElement.scrollTop || document.body.scrollTop;
      // 平滑滚动，时长500ms，每10ms一跳，共50跳
      let step = total / 50;
      if (total > distance) {
        smoothDown();
      } else {
        let newTotal = distance - total;
        step = newTotal / 50;
        smoothUp();
      }
      function smoothDown() {
        if (distance < total) {
          distance += step;
          document.body.scrollTop = distance;
          document.documentElement.scrollTop = distance;
          setTimeout(smoothDown, 10);
        } else {
          document.body.scrollTop = total;
          document.documentElement.scrollTop = total;
        }
      }
      function smoothUp() {
        if (distance > total) {
          distance -= step;
          document.body.scrollTop = distance;
          document.documentElement.scrollTop = distance;
          setTimeout(smoothUp, 10);
        } else {
          document.body.scrollTop = total;
          document.documentElement.scrollTop = total;
        }
      }
    },

    initEchart() {
      var mapchart = echarts.init(document.getElementById("main"));
      mapchart.setOption(optionMap);
      window.onresize = function() {
        // resizeMyChartContainer();
        mapchart.resize();
      };
    }
  }
};
</script>

<style scoped lang="scss">
.menu-box {
  width: 100%;
  height: 100%;
  background-color: #12355b;
  .menu-title {
    max-width: 100%;
    margin: 0 auto;
    font-weight: 500;
    font-size: 16px;
    line-height: 46px;
    color: #f4caab;
    border-bottom: 1px solid rgba(74, 112, 153, 0.9);
  }
  .menu {
    width: 90%;
    text-align: left;
    padding: 5%;
    &-list {
      width: 80%;
      margin: 0 auto;
      height: 52px;
      line-height: 52px;
      color: #f4caab;
      border-bottom: 1px solid rgba(74, 112, 153, 0.9);
    }
  }
}
.home-box {
  width: 100%;
  padding-top: 46px;
  .top-box {
    width: 95%;
    margin: 0 auto;
    .swiper-box {
      width: 100%;
      height: 60vw;
      margin-top: 10px;
      .van-swipe {
        width: 100%;
        height: 100%;
        border-radius: 2vw;
        overflow: hidden;
        .van-swipe-item {
          overflow: hidden;
        }
        .van-image {
          width: 100%;
          height: 100%;
        }
      }
    }
  }

  .content-box {
    width: 100%;
    padding-top: 10vw;
    padding-bottom: 10vw;
    position: relative;
    .content-title {
      width: 100%;
      .van-image {
        width: 100%;
        height: 7vw;
      }
      .content-title-text {
        height: 12px;
        text-align: center;
        display: inline-flex;
        align-items: center;
        margin: 0 auto;
        margin-top: 4vw;
        .van-image {
          height: 8px;
        }
        span {
          font-size: 10px;
          line-height: 12px;
          font-family: FZLanTingHei-R-GBK;
          font-weight: 400;
          color: #000000;
          white-space: nowrap;
        }
      }
    }
    .intro {
      position: absolute;
      bottom: 20px;
      left: 10px;
      color: #fff;
      .top {
        display: flex;
        justify-content: start;
        align-items: center;
        div {
          line-height: 21px;
          font-size: 10px;
          margin-left: 10px;
        }
        .blue {
          display: block;
          width: 10px;
          height: 10px;
          background: #2f54eb;
          border-radius: 10px;
        }
        .yellow {
          display: block;
          width: 10px;
          height: 10px;
          background: #ff6300;
          border-radius: 10px;
        }
      }
    }
  }

  .footer-box {
    width: 100%;
    background-color: #12355b;
    text-align: center;
    padding-bottom: 20px;
    padding-top: 10px;
    .bottom {
      width: 100%;
      a {
        color: #f4caab;
        font-size: 2vw;
        margin-bottom: 10px;
        display: block;
      }
    }
    .footer-list-box {
      width: 86%;
      margin: 0 auto;
      padding: 5% 2%;
      .footer-title {
        font-size: 4vw;
        color: #f4caab;
        font-weight: bold;
      }
      .footer-list {
        display: flex;
        width: 100%;
        align-items: center;
        text-align: center;
        font-size: 2vw;
        line-height: 8vw;
        color: #f4caab;
        a {
          color: #f4caab;
          display: inline-flex;
          align-items: center;
        }
        .van-icon {
          font-size: 5vw;
          margin-right: 5px;
        }

        .van-image {
          width: 40vw;
          height: 40vw;
        }
      }
      .footer-list2 {
        display: flex;
        justify-content: center;
        width: 100%;
        align-items: center;
        text-align: center;
        font-size: 2vw;
        line-height: 8vw;
        color: #f4caab;
        a {
          color: #f4caab;
          display: inline-flex;
          align-items: center;
        }
        .van-icon {
          font-size: 5vw;
          margin-right: 5px;
        }

        .van-image {
          width: 40vw;
          height: 40vw;
        }
      }
    }
  }

  .margin {
    width: 95%;
    margin: 0 auto;
  }
  .orange-bg {
    background-image: linear-gradient(
      rgba(255, 217, 169, 0.1),
      rgba(221, 144, 65, 0.1)
    );
  }
  .dark-bg {
    background-color: #404a59;
  }

  .background-image {
    position: absolute;
    z-index: -1;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
  }
}

.do-what-box {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  margin-top: 10vw;
  justify-content: space-between;
  .do-what-list {
    width: 48.8%;
    height: 68vw;
    background-color: #dfdfdf;
    position: relative;
    border-radius: 1vw;
    margin-bottom: 8vw;

    .do-what-list-title {
      position: absolute;
      width: 12vw;
      height: 12vw;
      left: 50%;
      top: -6vw;
      margin-left: -6vw;
      .van-image {
        width: 100%;
        height: 100%;
        z-index: 2;
      }
    }

    .do-what-list-image {
      width: 100%;
      height: 40vw;
      .van-image {
        width: 100%;
        height: 100%;
      }
    }

    .do-what-list-text-box {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 28vw;
      background-color: #12355b;
      border-bottom-left-radius: 1vw;
      border-bottom-right-radius: 1vw;
      h2 {
        font-size: 16px;
        font-family: FZLanTingHei-B-GBK;
        font-weight: bold;
        color: #f4caab;
        margin: 3vw 0 1vw 0;
      }
      .van-image {
        width: 60%;
        height: 10px;
        margin-left: -4px;
      }
      p {
        width: 100%;
        text-align: center;
        margin: 0 auto;
        margin-top: 2vw;
        font-size: 2vw;
        font-family: FZLanTingHei-L-GBK;
        font-weight: 400;
        color: #ffffff;
        line-height: 4vw;
      }
    }
  }
}

.change-world-box {
  width: 90%;
  display: inline-flex;
  justify-content: space-around;
  margin: 0 auto;
  margin-top: 10vw;
  padding-bottom: 4vw;
  .change-world-list {
    width: 30%;
    height: 40vw;
    .van-image {
      width: 20vw !important;
      height: 20vw !important;
    }
    h2 {
      font-size: 16px;
      font-family: FZLanTingHei-B-GBK;
      font-weight: bold;
      color: #14365d;
      margin: 3vw 0 1vw 0;
    }
    p {
      width: 100%;
      text-align: center;
      margin: 0 auto;
      margin-top: 2vw;
      font-size: 2vw;
      font-family: FZLanTingHei-L-GBK;
      font-weight: 400;
      line-height: 4vw;
      color: #666666;
    }
  }
}

.epidemic-situation-box {
  width: 90%;
  display: inline-flex;
  justify-content: space-around;
  margin: 0 auto;
  margin-top: 5vw;
  padding-bottom: 4vw;
  .epidemic-situation-list {
    width: 100%;
    &-image {
      width: 24vw !important;
      height: 24vw !important;
      margin: 0 auto;
      border-radius: 24vw;
      .van-image {
        width: 100% !important;
        height: 100% !important;
      }
    }

    h2 {
      font-size: 16px;
      font-family: FZLanTingHei-B-GBK;
      font-weight: bold;
      color: white;
      margin: 5vw 0 1vw 0;
    }
    p {
      width: 90%;
      text-align: center;
      margin: 0 auto;
      margin-top: 2vw;
      font-size: 2vw;
      font-family: FZLanTingHei-L-GBK;
      font-weight: 400;
      line-height: 4vw;
      color: white;
    }
  }
}

.cxc-system-box {
  width: 90%;
  margin: 0 auto;
  margin-top: 15vw;
  padding-bottom: 4vw;
  .cxc-system-list {
    width: 100%;
    height: 80vw;
    background-color: #dfdfdf;
    position: relative;
    border-radius: 1vw;
    margin-bottom: 8vw;

    .cxc-system-list-title {
      position: absolute;
      width: 35vw;
      height: 12vw;
      left: 50%;
      top: -6vw;
      margin-left: -17.5vw;
      .cxc-system-list-title-text {
        position: absolute;
        color: white;
        width: 35vw;
        height: 12vw;
        line-height: 12vw;
        text-align: center;
        left: 50%;
        top: 0;
        margin-left: -17.5vw;
        z-index: 101;
      }
      .van-image {
        width: 100%;
        height: 100%;
        z-index: 2;
      }
    }

    .cxc-system-list-image {
      width: 100%;
      height: 52vw;

      .van-image {
        width: 100%;
        height: 100%;
      }
    }
    .cxc-system-title-text {
      position: absolute;
      color: white;
      width: 35vw;
      height: 12vw;
      line-height: 12vw;
      text-align: center;
      left: 50%;
      top: 0;
      margin-left: -17.5vw;
      z-index: 101;
    }

    .cxc-system-list-text-box {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 28vw;
      background-color: #12355b;
      border-bottom-left-radius: 1vw;
      border-bottom-right-radius: 1vw;
      display: flex;
      align-items: center;

      h2 {
        font-size: 16px;
        font-family: FZLanTingHei-B-GBK;
        font-weight: bold;
        color: #f4caab;
        margin: 3vw 0 1vw 0;
      }

      .van-image {
        width: 60%;
        height: 10px;
        margin-left: -4px;
      }

      p {
        width: 100%;
        text-align: center;
        margin: 2vw auto;
        font-size: 2vw;
        font-family: FZLanTingHei-L-GBK;
        font-weight: 400;
        color: #ffffff;
        line-height: 4vw;
      }
    }
  }
}

.product-box {
  width: 90%;
  margin: 0 auto;
  margin-top: 15vw;
  padding-bottom: 4vw;
  .product-list {
    width: 100%;
    height: 56vw;
    background-color: #dfdfdf;
    position: relative;
    border-radius: 1vw;
    margin-bottom: 8vw;
    .product-list-title {
      position: absolute;
      width: 35vw;
      height: 12vw;
      left: 50%;
      top: -6vw;
      margin-left: -17.5vw;
      .van-image {
        width: 100%;
        height: 100%;
        z-index: 100;
      }
      .product-list-title-text {
        position: absolute;
        color: white;
        width: 35vw;
        height: 12vw;
        line-height: 12vw;
        text-align: center;
        left: 50%;
        top: 0;
        margin-left: -17.5vw;
        z-index: 101;
      }
    }

    .product-list-image {
      width: 100%;
      height: 52vw;
      .van-swipe {
        width: 100%;
        height: 100%;
        .van-image {
          width: 100%;
          height: 100%;
        }
      }
    }

    .product-list-text-box-top {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 4vw;
      background-color: #12355b;
      border-top-left-radius: 1vw;
      border-top-right-radius: 1vw;
      display: flex;
      align-items: center;
    }

    .product-list-text-box {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 4vw;
      background-color: #12355b;
      border-bottom-left-radius: 1vw;
      border-bottom-right-radius: 1vw;
      display: flex;
      align-items: center;
      h2 {
        font-size: 16px;
        font-family: FZLanTingHei-B-GBK;
        font-weight: bold;
        color: #f4caab;
        margin: 3vw 0 1vw 0;
      }
      .van-image {
        width: 60%;
        height: 10px;
        margin-left: -4px;
      }
      p {
        width: 100%;
        text-align: center;
        margin: 2vw auto;
        font-size: 2vw;
        font-family: FZLanTingHei-L-GBK;
        font-weight: 400;
        color: #ffffff;
        line-height: 4vw;
      }
    }
  }
}

.serve-box {
  width: 90%;
  margin: 0 auto;
  text-align: center;
  p {
    font-size: 2vw;
    font-family: FZLanTingHei-R-GBK;
    font-weight: 400;
    color: #ffffff;
    line-height: 6vw;
  }
}

.construction-process-box {
  width: 90%;
  margin: 0 auto;
  margin-top: 15vw;
  padding-bottom: 4vw;
  .construction-process-list {
    width: 100%;
    height: 74vw;
    background-color: #dfdfdf;
    position: relative;
    border-radius: 1vw;
    margin-bottom: 13vw;
    .construction-process-list-title {
      position: absolute;
      width: 20vw;
      height: 20vw;
      left: 50%;
      top: -10vw;
      margin-left: -10vw;
      .van-image {
        width: 100%;
        height: 100%;
        z-index: 2;
      }
    }

    .construction-process-list-image {
      width: 100%;
      height: 52vw;
      .van-image {
        width: 100%;
        height: 100%;
      }
    }

    .construction-process-list-text-box-top {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 4vw;
      background-color: #12355b;
      border-top-left-radius: 1vw;
      border-top-right-radius: 1vw;
      display: flex;
      align-items: center;
    }

    .construction-process-list-text-box {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 22vw;
      background-color: #12355b;
      border-bottom-left-radius: 1vw;
      border-bottom-right-radius: 1vw;
      display: flex;
      align-items: center;
      h2 {
        font-size: 16px;
        font-family: FZLanTingHei-B-GBK;
        font-weight: bold;
        color: #f4caab;
        margin: 3vw 0 1vw 0;
      }
      .van-image {
        width: 60%;
        height: 10px;
        margin-left: -4px;
      }
      p {
        width: 100%;
        text-align: center;
        margin: 2vw auto;
        font-size: 2vw;
        font-family: FZLanTingHei-L-GBK;
        font-weight: 400;
        color: #ffffff;
        line-height: 4vw;
      }
    }
  }
}

.map-box {
  width: 100%;
  margin-top: 10vw;
}

.banner-first {
  position: relative;
  &-title {
    position: absolute;
    width: 40vw !important;
    top: -9vw;
    left: 50%;
    margin-left: -20vw;
    .van-image {
      width: 100%;
      height: 100%;
    }
  }
  &-hint {
    position: absolute;
    width: 60vw !important;
    top: 8vw;
    left: 50%;
    margin-left: -30vw;
    .van-image {
      width: 100%;
      height: 100%;
    }
  }

  &-text {
    position: absolute;
    width: 86vw !important;
    top: 20vw;
    left: 50%;
    margin-left: -43vw;
    .van-image {
      width: 100%;
      height: 100%;
    }
  }
}

.banner-second {
  height: 100%;
}

.van-nav-bar {
  background-color: #12355b;
  color: white;
  position: fixed;
  top: 0;
  width: 100vw;
  z-index: 200;
}
</style>
<style>
.van-nav-bar__title,
.van-popup__close-icon {
  color: #f4caab;
}
.van-nav-bar .van-icon {
  color: #f4caab;
}
.van-popup__close-icon--top-left {
  top: 12px;
}
</style>
